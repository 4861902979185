import Sidebar from './sidebar';
import Header from './header';
import Footer from './footer';
import { RWebShare } from "react-web-share";
import Select from 'react-select';
import './style.css'
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestJobDetails, requestEditJob} from "../Redux/actions";
import Swal from "sweetalert2";
import image from "../images/extraLogo.png";

import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import axios from 'axios';

function Jobs(props) {
    const params = useParams();
    const navigate = useNavigate();
    const [jobData, setjob] = useState([]);

    const [editorState, onDescStateChange] = useState(() => EditorState.createEmpty(),);
    const [roleState, onRoleStateChange] = useState(() => EditorState.createEmpty(),);
    const [profileState, onProfileStateChange] = useState(() => EditorState.createEmpty(),);
    const [perkState, onPerkStateChange] = useState(() => EditorState.createEmpty(),);
    const [salaryState, onSalaryStateChange] = useState(() => EditorState.createEmpty(),);
    const [companyState, onCompanyStateChange] = useState(() => EditorState.createEmpty(),);

    const getHtml     = editorState => draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const getHtmlRole = roleState => draftToHtml(convertToRaw(roleState.getCurrentContent()));
    const getHtmlProfile = profileState => draftToHtml(convertToRaw(profileState.getCurrentContent()));
    const getHtmlPerk = perkState => draftToHtml(convertToRaw(perkState.getCurrentContent()));
    const getHtmlSalary = salaryState => draftToHtml(convertToRaw(salaryState.getCurrentContent()));
    const getHtmlCompany = companyState => draftToHtml(convertToRaw(companyState.getCurrentContent()));

    const [SalaryPeriod, setSalaryPeriod] = useState([]);
    const [career_levels, setcareer_levels] = useState([]);
    const [categories, setcategories] = useState([]);
    const [industries, setindustries] = useState([]);
    const [currencies, setcurrencies] = useState([]);
    const [degree_levels, setdegree_levels] = useState([]);
    const [degree, setdegree] = useState(0);
    const [courses, setcourses] = useState([]);
    const [course, setcourse] = useState(0);
    const [specializations, setspecializations] = useState([]);
    const [specialization, setspecialization] = useState(0);
    const [selectedspecialization, setselectedspecialization] = useState([]);
    const [selectedspecializationstr, setselectedspecializationstr] = useState("");
    const [prevspecialization, setprevspecialization] = useState([]);

    const [functional_areas, setfunctional_areas] = useState([]);
    const [job_shifts, setjob_shifts] = useState([]);
    const [skills, setskills] = useState([]);
    const [tags, settags] = useState([]);
    const [prefs, setprefs] = useState([]);
    const [types, settypes] = useState([]);
    const [position, setposition] = useState([]);
    const [country, setcountry] = useState(0);
    const [state, setstateId] = useState(0);
    const [city, setcity] = useState("");
    const [locations, setlocations] = useState([]);
    const [defaultloc, setdefaultloc] = useState([]);
    const [states, setstates] = useState([]);
    const [countries, setcountries] = useState([]);
  

    const [selectedskill, setselectedskill] = useState([]);
    const [selectedskillstr, setselectedskillstr] = useState("");
    const [prevskill, setprevskill] = useState([]);
    const [selectedtag, setselectedtag] = useState([]);
    const [selectedtagstr, setselectedtagstr] = useState("");    
    const [prevtag, setprevtag] = useState([]);
    const [jobpref, setsjobpref] = useState([]);
    const [selectedpref, setselectedpref] = useState([]);
    const [selectedprefstr, setselectedprefstr] = useState("");
    const [prevpref, setprevpref] = useState([]);
    const [selectedlocation, setselectedlocation] = useState([]);
    const [selectedlocationstr, setselectedlocationstr] = useState("");    
    const [prevlocation, setprevlocation] = useState([]);

    const [token, settoken] = useState("");
    const [companies, setcompanies] = useState([]);
    const [questions, setquestions] = useState([]);

    const [errorCompany, seterrorCompany] = useState("");
    const [errorTitle, seterrorTitle] = useState("");
    const [errorLocation, seterrorLocation] = useState("");
    const [errorType, seterrorType] = useState("");
    const [errorEmpType, seterrorEmpType] = useState("");
    const [errorCategory, seterrorCategory] = useState("");
    const [errorIndustry, seterrorIndustry] = useState("");
    const [errorPrefIndustry, seterrorPrefIndustry] = useState("");
    const [errorGender, seterrorGender] = useState("");
    const [errorExpiry, seterrorExpiry] = useState("");
    const [errorFrom, seterrorFrom] = useState("");
    const [errorTo, seterrorTo] = useState("");
    const [errorCurrency, seterrorCurrency] = useState("");
    const [errorPeriod, seterrorPeriod] = useState("");
    const [errorCareer, seterrorCareer] = useState("");
    const [errorShift, seterrorShift] = useState("");
    const [errorDegree, seterrorDegree] = useState("");
    const [errorCourse, seterrorCourse] = useState("");
    const [errorSpecialization, seterrorSpecialization] = useState("");
    const [errorFunctional, seterrorFunctional] = useState("");
    const [errorPosition, seterrorPosition] = useState("");
    const [errorDesc, seterrorDesc] = useState("");
    const [errorName, seterrorName] = useState("");
    const [errorEmail, seterrorEmail] = useState("");
    const [errorVacancy, seterrorVacancy] = useState("");
    const [errorcountry, seterrorcountry] = useState("");
    const [errorstate, seterrorstate] = useState("");
  
    const [addmodal, setaddModal] = useState(false);

    const [id, setid] = useState(0);
    const [editdata, seteditdata] = useState({
      title: "", location: "", type: 0, emptype: 0, category: 0, industry: 0, selectedprefstr: '',
      skill: 0, gender: 0, expiry_date: '', salary_from: 0, salary_to: 0, currency: 0, salary_period: 0, career_levels: 0, shift: 0, degree_level: 0,
      functional_areas: 0, position: 0, min_exp: 0, max_exp: 0, description: '', desc_perks: '', desc_profile: '', desc_role: '', Recruiter_name: '',
      area: '', region: '', zipcode: '', designation: '',repost_after: '', country: 101,
      Recruiter_email: '', vacancy: '', ref_code: '', salary_details: '', salary_per: 0,
      Recruiter_comp: '', Recruiter_phoneno: '', Recruiter_details: '', Recruiter_website: '', Recruiter_visible: 1, questions: 0});

    const mystyle = {color: "#D10000", backgroundColor: "#FFD2D2", padding: "3px 10px", border: "1px solid red", borderRadius: "5px", marginTop: "5px"};
    const editorclass = { backgroundColor: "lightgray", padding: "1rem", border: "1px solid #ccc"};  
    const wrapperclass = { padding: "1rem", border: "1px solid #ccc"}
    const toolbarclass = { border: "1px solid #ccc"}

    useEffect(() => {
        props.requestJobDetails({
            id: params.id,
        });
        setjob(props.data.jobDetailsData);
    }, []);

    //form field
    useEffect(() => {
      let formfieldData = props.data.loginData.data.data;
      setSalaryPeriod(formfieldData.SalaryPeriod);
      setcareer_levels(formfieldData.career_levels);
      setcategories(formfieldData.categories);
      setindustries(formfieldData.industries);
      setprefs(formfieldData.industries);
      setcurrencies(formfieldData.currencies);
      setdegree_levels(formfieldData.degree_levels);
      setfunctional_areas(formfieldData.fun_area);
      setjob_shifts(formfieldData.job_shifts);
      setskills(formfieldData.skills || []);
      settags(formfieldData.tags);
      settypes(formfieldData.types);
      setposition(formfieldData.position);
      settoken(formfieldData.token);
      setcompanies(formfieldData.companies);
      setquestions(formfieldData.questions);
    }, [props.data.loginData.data.data]);
      
    function onchangeeditdata(e) {
      seteditdata((editdata) => ({
        ...editdata,
        [e.target.name]: e.target.value,
      }));
    }

    useEffect(() => {
      let countryData = props.data.loginData.data.data.countries;
      if (countryData !== undefined) {
          setcountries(countryData);
      }
    }, [props.data.loginData.data.data]);
  
    function onChangeCountry(e) {
      console.log(e.target.value);
      setcountry(e.target.value);
      axios.get('https://day.recruiterserp.com/api/state/' + e.target.value)
      .then(res=>{
          setstates(res.data.data.states);
      })
      .catch(err=>{
          console.log(err);
      })
    }    
  
    function onChangeState(e) {
      setstateId(e.target.value);
      axios.get('https://day.recruiterserp.com/api/city/' + e.target.value)
      .then(res=>{
          setlocations(res.data.data.cities);
      })
      .catch(err=>{
          console.log(err);
      })
    }
  
    const onChangeLocation = (event) => {
      setselectedlocation([]);
      setselectedlocationstr("");
      event.map((option) => (
        selectedlocation.push(option.key)
      ));
    console.log(selectedlocation);
      setselectedlocationstr(selectedlocation.toString());
      setdefaultloc(event);
    }

    function onChangeDegree(e) {
      console.log(e.target.value);
      setdegree(e.target.value);
      axios.get('https://day.recruiterserp.com/api/courses/' + e.target.value)
      .then(res=>{
          setcourses(res.data.data.courses);
      })
      .catch(err=>{
          console.log(err);
      })
    }    

    function onChangeCourse(e) {
      console.log(e.target.value);
      setcourse(e.target.value);
      axios.get('https://day.recruiterserp.com/api/specializations/' + e.target.value)
      .then(res=>{
          setspecializations(res.data.data.specializations);
      })
      .catch(err=>{
          console.log(err);
      })
    }    

    function onChangeSpecialization(e) {
      setselectedspecialization([]);
      setselectedspecializationstr("");
      e.map((option) => (
        selectedspecialization.push(option.key)
      ));
      setselectedspecializationstr(selectedspecialization.toString());
      console.log('selectedspecialization');
      console.log(selectedspecializationstr);
      setprevspecialization(e);
    }

    useEffect(() => {
      setprevpref(jobpref);
      setselectedpref([]);
      setselectedprefstr("");
      prevpref.map((option) => (
        selectedpref.push(option.id)
      ));
      const dflt= jobpref.map((option) =>{return {label: option.name, value: option.name, key: option.id}});
      if(dflt !== []){
        setprevpref(dflt);
      }
      setselectedprefstr(selectedpref.toString());
    }, [jobpref]);
    
    useEffect(() => {
      let job = props.data.jobDetailsData;
      if (job !== undefined) {
        if (job.data) {
          seteditdata(job.data.data.job[0]);
          const prefData   = job.data.data.job[0].pref_industry.split(',').map(Number);
          const filterprefData = prefs.filter((el) => prefData.includes(el.id));    
          setsjobpref(filterprefData);

          if(job.data.data.job[0].salary_details !== null){
            onSalaryStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(
                convertFromHTML(job.data.data.job[0].salary_details)
              )
            ));
          }
          if(job.data.data.job[0].description !== null){
            onDescStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(
              convertFromHTML(job.data.data.job[0].description)
              )
            ));  
          }
          if(job.data.data.job[0].desc_perks !== null){
            onPerkStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(
              convertFromHTML(job.data.data.job[0].desc_perks)
              )
            ));  
          }
          if(job.data.data.job[0].desc_profile !== null){
            onProfileStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(
              convertFromHTML(job.data.data.job[0].desc_profile)
              )
            ));  
          }
          if(job.data.data.job[0].desc_role !== null){
            onRoleStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(
              convertFromHTML(job.data.data.job[0].desc_role)
              )
            ));  
          }
          if(job.data.data.job[0].Recruiter_details !== null){
            onCompanyStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(
              convertFromHTML(job.data.data.job[0].Recruiter_details)
              )
            ));  
          }

          if(job.data.data.job[0].tag !== null){
            const tagData =  job.data.data.job[0].tag.split(',').map(Number);
            let filterData= tags.filter((el) => tagData.includes(el.id));
            setprevtag(filterData);
            setselectedtag([]);
            setselectedtagstr("");
            filterData.map((option) => (
              selectedtag.push(option.id)
            ));
            const dflt= filterData.map((option) =>{return {label: option.name, value: option.name, key: option.id}});
            setprevtag(dflt);
            setselectedtagstr(selectedtag.toString());  
          }

          setcountry(job.data.data.job[0].country);
          setstateId(job.data.data.job[0].state);
          setcity(job.data.data.job[0].location);
          axios.get('https://day.recruiterserp.com/api/state/' + job.data.data.job[0].country)
          .then(res=>{
              setprevlocation([]);
              setselectedlocationstr("");
              setselectedlocation([]);
              setstates(res.data.data.states);
          })
          .catch(err=>{
              console.log(err);
          })
    
          setdegree(job.data.data.job[0].degree_level);
          axios.get('https://day.recruiterserp.com/api/courses/' + degree)
          .then(res=>{
              setcourses(res.data.data.courses);
              setcourse(job.data.data.job[0].course);
          })
          .catch(err=>{
              console.log(err);
          })
        }
      }
    }, [props.data.jobDetailsData]);

    useEffect(() => {
      let job = props.data.jobDetailsData;
      if (job !== undefined) {
          if (job.data) {
              const loadLocation = async () => {
                const skillData =  job.data.data.job[0].skill.split(',').map(Number);
                let filterData  = skills.filter((el) => skillData.includes(el.id));
                console.log(filterData.length);
                setselectedskill([]);
                setselectedskillstr("");
                filterData.map((option) => (
                  selectedskill.push(option.id)
                ));
                const dflt= filterData.map((option) =>{return {label: option.name, value: option.name, key: option.id}});
                console.log(dflt);
                setprevskill(dflt);
                setselectedskillstr(selectedskill.toString());
              }
              loadLocation();
          }            
        }
    }, [props.data.jobDetailsData]);

    useEffect(() => {
        let job = props.data.jobDetailsData;
        if (job !== undefined) {
            if (job.data) {
                const loadLocation = async () => {  
                const res = await axios.get('https://day.recruiterserp.com/api/city/' + state);
                    setlocations(res.data.data.cities);
                    const locationData =  job.data.data.job[0].location.split(',').map(Number);
                    setselectedlocation([]);
                    setselectedlocationstr("");
                    const prv = res.data.data.cities.filter((el) => locationData.includes(el.id));
                    prv.map((option) => (
                        selectedlocation.push(option.id)
                    ));                    
                    const dflt= prv.map((option) =>{return {label: option.name, value: option.name, key: option.id}});
                    console.log(dflt);
                    setdefaultloc(dflt);
                    setselectedlocationstr(selectedlocation.toString());
                }
                loadLocation();
            }            
        }
    }, [props.data.jobDetailsData]);

    useEffect(() => {
      let job = props.data.jobDetailsData;
      if (job !== undefined) {
          if (job.data) {
              const loadSpec = async () => {  
              const res = await axios.get('https://day.recruiterserp.com/api/specializations/' + job.data.data.job[0].course);
              setspecializations(res.data.data.specializations);
              const specializationData =  job.data.data.job[0].specialization.split(',').map(Number);
              setselectedspecialization([]);
              setselectedspecializationstr("");
              const prv = res.data.data.specializations.filter((el) => specializationData.includes(el.id));
              prv.map((option) => (
                  selectedspecialization.push(option.id)
              ));
              const dflt= prv.map((option) =>{return {label: option.name, value: option.name, key: option.id}});
              setprevspecialization(dflt);
              setselectedspecializationstr(selectedspecialization.toString());
            }
            loadSpec();
          }
      }
  }, [props.data.jobDetailsData]);

    function validateeditCompany() {
      let formIsValid = false;
      if (!editdata["company_id"] === "0") {
        formIsValid = false;
        seterrorCompany("*Select Employer.");
      } else if (typeof !editdata["company_id"] === "undefined") {
        formIsValid = false;
        seterrorCompany("*Select Employer.");
      } else {
        formIsValid = true;
        seterrorCompany("");
      }
      return formIsValid;
    }

    function validateeditTitle() {
      let formIsValid = false;
      if (!editdata["title"]) {
        formIsValid = false;
        seterrorTitle("*Enter job title.");
      } else if (typeof !editdata["title"] === "undefined") {
        formIsValid = false;
        seterrorTitle("*Enter job title.");
      } else {
        formIsValid = true;
        seterrorTitle("");
      }
      return formIsValid;
    }
    
    function validateeditVacancy() {
      let formIsValid = false;
      if (!editdata["vacancy"] === undefined) {
        formIsValid = false;
        seterrorVacancy("*Enter job vacancy.");
      } else if (editdata["vacancy"] <= 0) {
        formIsValid = false;
        seterrorVacancy("*Vacancy shold be more than 0.");
      } else {
        formIsValid = true;
        seterrorVacancy("");
      }
      return formIsValid;
    }
  
    function validateeditType() {
      let formIsValid = false;
      if (!editdata["type"] === "0") {
        formIsValid = false;
        seterrorType("*Select job type.");
      } else if (typeof !editdata["type"] === "undefined") {
        formIsValid = false;
        seterrorType("*Select job type.");
      } else {
        formIsValid = true;
        seterrorType("");
      }
      return formIsValid;
    }
  
    function validateeditIndustry() {
      let formIsValid = false;
      if (!editdata["industry"] === "0") {
        formIsValid = false;
        seterrorIndustry("*Select the industry.");
      } else if (typeof !editdata["industry"] === "undefined") {
        formIsValid = false;
        seterrorIndustry("*Select the industry.");
      } else {
        formIsValid = true;
        seterrorIndustry("");
      }
      return formIsValid;
    }

    function validateeditPrefIndustry() {
      let formIsValid = false;
      if (!selectedprefstr === "") {
        formIsValid = false;
        seterrorPrefIndustry("*Select the Pref. industry.");
      } else if (typeof !selectedprefstr === "undefined") {
        formIsValid = false;
        seterrorPrefIndustry("*Select the Pref. industry.");
      } else {
        formIsValid = true;
        seterrorPrefIndustry("");
      }
      return formIsValid;
    }

    function validateeditEmpType() {
      let formIsValid = false;
      if (!editdata["emptype"] === "0") {
        formIsValid = false;
        seterrorEmpType("*Select employment type.");
      } else if (typeof !editdata["emptype"] === "undefined") {
        formIsValid = false;
        seterrorEmpType("*Select employment type.");
      } else {
        formIsValid = true;
        seterrorEmpType("");
      }
      return formIsValid;
    }
    function validateeditCategory() {
      let formIsValid = false;
      if (!editdata["category"] === "0") {
        formIsValid = false;
        seterrorCategory("*Select job category.");
      } else if (typeof !editdata["category"] === "undefined") {
        formIsValid = false;
        seterrorCategory("*Select job category.");
      } else {
        formIsValid = true;
        seterrorCategory("");
      }
      return formIsValid;
    }
    function validateeditGender() {
      let formIsValid = false;
      if (typeof !editdata["gender"] === "undefined") {
        formIsValid = false;
        seterrorGender("*Select gender for job.");
      } else {
        formIsValid = true;
        seterrorGender("");
      }
      return formIsValid;
    }
    function validateeditExpiry() {
      let formIsValid = false;
      var Today = new Date();
      if (typeof !editdata["expiry_date"] !== "undefined") {
        if (new Date(editdata["expiry_date"]).getTime() <= Today.getTime()) {
          formIsValid = false;
          seterrorExpiry("*Please select proper date.");
        } else {
          formIsValid = true;
          seterrorExpiry("");
        }
      } else {
        formIsValid = true;
        seterrorExpiry("");
      }
      return formIsValid;
    }
    function validateeditFrom() {
      let formIsValid = false;
      if (!editdata["salary_from"] === undefined) {
        formIsValid = false;
        seterrorFrom("*Enter minimum salary.");
      } else if (editdata["salary_from"] <= 0) {
        formIsValid = false;
        seterrorFrom("*Minimum salary shold be more than 0.");
      } else {
        formIsValid = true;
        seterrorFrom("");
      }
      return formIsValid;
    }
    function validateeditTo() {
      let formIsValid = false;
      if (!editdata["salary_to"] === undefined) {
        formIsValid = false;
        seterrorTo("*Enter maximum salary.");
      } else if (editdata["salary_to"] <= !editdata["salary_from"]) {
        formIsValid = false;
        seterrorTo("*Maximum salary shold be more than Minimum salary.");
      } else {
        formIsValid = true;
        seterrorTo("");
      }
      return formIsValid;
    }
    function validateeditCurrency() {
      let formIsValid = false;
      if (!editdata["currency"] === "0") {
        formIsValid = false;
        seterrorCurrency("*Select salary currency.");
      } else if (typeof !editdata["currency"] === "undefined") {
        formIsValid = false;
        seterrorCurrency("*Select salary currency.");
      } else {
        formIsValid = true;
        seterrorCurrency("");
      }
      return formIsValid;
    }
    function validateeditPeriod() {
      let formIsValid = false;
      if (!editdata["salary_period"] === "0") {
        formIsValid = false;
        seterrorPeriod("*Select salary period.");
      } else if (typeof !editdata["salary_period"] === "undefined") {
        formIsValid = false;
        seterrorPeriod("*Select salary period.");
      } else {
        formIsValid = true;
        seterrorPeriod("");
      }
      return formIsValid;
    }
    function validateeditCareer() {
      let formIsValid = false;
      if (!editdata["career_level"] === "0") {
        formIsValid = false;
        seterrorCareer("*Select career level.");
      } else if (typeof !editdata["career_level"] === "undefined") {
        formIsValid = false;
        seterrorCareer("*Select career level.");
      } else {
        formIsValid = true;
        seterrorCareer("");
      }
      return formIsValid;
    }
    function validateeditShift() {
      let formIsValid = false;
      if (!editdata["shift"] === "0") {
        formIsValid = false;
        seterrorShift("*Select job shift.");
      } else if (typeof !editdata["shift"] === "undefined") {
        formIsValid = false;
        seterrorShift("*Select job shift.");
      } else {
        formIsValid = true;
        seterrorShift("");
      }
      return formIsValid;
    }

    function validateeditDegree() {
      let formIsValid = false;
      if (!editdata["degree_level"] === "0") {
        formIsValid = false;
        seterrorDegree("*Select degree level.");
      } else if (typeof !editdata["degree_level"] === "undefined") {
        formIsValid = false;
        seterrorDegree("*Select degree level.");
      } else {
        formIsValid = true;
        seterrorDegree("");
      }
      return formIsValid;
    }
    function validateeditFunctional() {
      let formIsValid = false;
      if (!editdata["functional_area"] === "0") {
        formIsValid = false;
        seterrorFunctional("*Select functional area.");
      } else if (typeof !editdata["functional_area"] === "undefined") {
        formIsValid = false;
        seterrorFunctional("*Select functional area.");
      } else {
        formIsValid = true;
        seterrorFunctional("");
      }
      return formIsValid;
    }
    function validateeditPosition() {
      let formIsValid = false;
      if (!editdata["position"] === "0") {
        formIsValid = false;
        seterrorPosition("*Select job position.");
      } else if (typeof !editdata["position"] === "undefined") {
        formIsValid = false;
        seterrorPosition("*Select job position.");
      } else {
        formIsValid = true;
        seterrorPosition("");
      }
      return formIsValid;
    }
    function validateeditDesc() {
      let formIsValid = false;
      if (!editdata["description"]) {
        formIsValid = false;
        seterrorDesc("*Enter job description.");
      } else if (typeof !editdata["description"] === "undefined") {
        formIsValid = false;
        seterrorDesc("*Enter job description.");
      } else {
        formIsValid = true;
        seterrorDesc("");
      }
      return formIsValid;
    }
    function validateeditName() {
      let formIsValid = false;
      if (!editdata["Recruiter_name"]) {
        formIsValid = false;
        seterrorName("*Enter recruiter name.");
      } else if (typeof !editdata["Recruiter_name"] !== "undefined") {
        if (!editdata["Recruiter_name"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
          formIsValid = false;
          seterrorName("*Please enter Alphabet characters only.");
        } else {
          formIsValid = true;
          seterrorName("");
        }
      }
      return formIsValid;
    }
    function validateeditEmail() {
      let formIsValid = false;
      if (!editdata["Recruiter_email"]) {
        formIsValid = false;
        seterrorEmail("*Enter recruiter E-mail ID.");
      } else if (typeof !editdata["Recruiter_email"] !== "undefined") {
        if (
          !editdata["Recruiter_email"].match(
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
          )
        ) {
          formIsValid = false;
          seterrorEmail("*Please enter valid E-mail ID.");
        } else {
          formIsValid = true;
          seterrorEmail("");
        }
      }
      return formIsValid;
    }

    function validateCourse() {
        let formIsValid = false;
        if (course === "0") {
          formIsValid = false;
          seterrorCourse("*Select course.");
        } else if (typeof course === "undefined") {
          formIsValid = false;
          seterrorCourse("*Select course.");
        } else {
          formIsValid = true;
          seterrorCourse("");
        }
        return formIsValid;
      }

      function validateSpecialization() {
        let formIsValid = false;
        if (selectedspecializationstr === "0") {
          formIsValid = false;
          seterrorSpecialization("*Select specialization.");
        } else if (typeof selectedspecializationstr === "undefined") {
          formIsValid = false;
          seterrorSpecialization("*Select specialization.");
        } else {
          formIsValid = true;
          seterrorSpecialization("");
        }
        return formIsValid;
      }
      function validatestate() {
        let formIsValid = false;
        if (state === "0") {
          formIsValid = false;
          seterrorstate("*Select your state.");
        } else if (typeof state === "undefined") {
          formIsValid = false;
          seterrorstate("*Select your state.");
        } else {
          formIsValid = true;
          seterrorstate("");
        }
        return formIsValid;
      }
    
      function validateLocation() {
        let formIsValid = false;
        if (!selectedlocationstr === "") {
          formIsValid = false;
          seterrorLocation("*Select the location.");
        } else if (typeof !selectedlocationstr === "undefined") {
          formIsValid = false;
          seterrorLocation("*Select the location.");
        } else {
          formIsValid = true;
          seterrorLocation("");
        }
        return formIsValid;
      }
      function validatecountry() {
        let formIsValid = false;
        if (country === "0") {
          formIsValid = false;
          seterrorcountry("*Select job country.");
        } else if (typeof country === "undefined") {
          formIsValid = false;
          seterrorcountry("*Select job country.");
        } else {
          formIsValid = true;
          seterrorcountry("");
        }
        return formIsValid;
      }
      function validateCompany() {
        let formIsValid = false;
        if (!editdata["company_id"] === "0") {
          formIsValid = false;
          seterrorCompany("*Select Employer.");
        } else if (typeof !editdata["company_id"] === "undefined") {
          formIsValid = false;
          seterrorCompany("*Select Employer.");
        } else {
          formIsValid = true;
          seterrorCompany("");
        }
        return formIsValid;
      }
  
    function validateEditForm() {
      let company_id = validateCompany(); let Title = validateeditTitle(); let Type = validateeditType(); let Category = validateeditCategory(); let Industry = validateeditIndustry();
      let pref_industry = validateeditPrefIndustry(); let Gender = validateeditGender(); 
      // let Expiry = validateeditExpiry(); 
      let From = validateeditFrom();
      let To = validateeditTo(); let Currency = validateeditCurrency(); let Period = validateeditPeriod(); let Career = validateeditCareer();
      let Shift = validateeditShift(); let Degree = validateeditDegree(); let Functional = validateeditFunctional(); let Position = validateeditPosition();
      let Name = validateeditName(); let Email = validateeditEmail(); let Vacancy = validateeditVacancy(); let Course = validateCourse(); let Specialization  = validateSpecialization(); let Location = validateLocation();
      let valid =
        company_id && Title && Type && Category && Industry && pref_industry && Gender && From && To && Currency && Period &&
        Career && Shift && Degree && Functional && Position && Name && Email && Vacancy && Course && Specialization && Location;
      return valid;
    }

      //edit job
  const saveedittoggle = (e) => {
    e.preventDefault();
    // if (validateEditForm()) {
      console.log(state);
      console.log(selectedlocationstr);
      props.requestEditJob({
        id: params.id,
        token: token,
        data: {
          company_id: editdata.company_id,
          comp_comp: editdata.comp_comp,
          designation: editdata.designation,
          title: editdata.title,
          country: country,
          state: state,
          location: selectedlocationstr,
          region: editdata.region,
          zipcode: editdata.zipcode,
          area: editdata.area,
          type: editdata.type,
          emptype: editdata.emptype,
          category: editdata.category,
          industry: editdata.industry,
          pref_industry: editdata.pref_industry,
          skill: selectedskillstr,
          gender: editdata.gender,
          expiry_date: editdata.expiry_date,
          salary_from: editdata.salary_from,
          salary_to: editdata.salary_to,
          currency: editdata.currency,
          salary_period: editdata.salary_period,
          career_level: editdata.career_level,
          shift: editdata.shift,
          tag: selectedtagstr,
          degree_level: degree,
          course: course,
          specialization: selectedspecializationstr,
          functional_area: editdata.functional_area,
          position: editdata.position,
          min_exp: editdata.min_exp,
          max_exp: editdata.max_exp,
          description: getHtml(editorState),
          desc_role:  getHtmlRole(roleState),
          desc_profile:  getHtmlProfile(profileState),
          desc_perks:  getHtmlPerk(perkState),
          Recruiter_name: editdata.Recruiter_name,
          Recruiter_email: editdata.Recruiter_email,
          vacancy: editdata.vacancy,
          show_salary: editdata.show_salary,
          ref_code: editdata.ref_code,
          salary_details:  getHtmlSalary(salaryState),
          salary_per: editdata.salary_per,
          Recruiter_comp: editdata.Recruiter_comp, Recruiter_phoneno: editdata.Recruiter_phoneno, 
          Recruiter_details: getHtmlCompany(companyState), Recruiter_website: editdata.Recruiter_website,
          Recruiter_visible: editdata.Recruiter_visible, questions: editdata.questions,
          repost_after: editdata.repost_after,
        },
      });
      navigate("/admin/jobs/1/10");
    //   seteditdata({
    //     company_id: 0, title: "", location: "", type: 0, emptype: 0, category: 0, industry: 0, selectedprefstr: '',
    //     skill: 0, gender: 0, expiry_date: '', salary_from: 0, salary_to: 0, currency: 0, salary_period: 0, career_levels: 0, shift: 0, degree_level: 0,
    //     functional_areas: 0, position: 0, min_exp: 0, max_exp: 0, description: '', desc_perks: '', desc_profile: '', desc_role: '', Recruiter_name: '',
    //     Recruiter_email: '', vacancy: '', ref_code: '', salary_details: '', salary_per: 0, comp_comp: '',
    //     Recruiter_comp: '', Recruiter_phoneno: '', Recruiter_details: '', Recruiter_website: '', Recruiter_visible: 1, questions: 0
    // });
      // setselectedprefstr("");
      // setselectedlocationstr("");
      // setselectedspecializationstr("");
      // setid(0);
    //   edittoggle();
    // }
  };

  //edit job response
  useEffect(() => {
    let editJobData = props.data.editJobData;
    if (editJobData !== undefined) {
      if (editJobData?.data?.status == "success") {
        Swal.fire(
          "Good job!",
          "Job Updated Successfully.",
          "success"
        );
        props.data.editJobData = undefined;
        seteditdata({
        company_id: 0, title: "", location: "", type: 0, emptype: 0, category: 0, industry: 0, selectedprefstr: '',
        skill: 0, gender: 0, expiry_date: '', salary_from: 0, salary_to: 0, currency: 0, salary_period: 0, career_levels: 0, shift: 0, degree_level: 0,
        functional_areas: 0, position: 0, min_exp: 0, max_exp: 0, description: '', desc_perks: '', desc_profile: '', desc_role: '', Recruiter_name: '',
        area: '', region: '', zipcode: '', designation: '',
        Recruiter_email: '', vacancy: '', ref_code: '', salary_details: '', salary_per: 0, comp_comp: '',
        Recruiter_comp: '', Recruiter_phoneno: '', Recruiter_details: '', Recruiter_website: '', Recruiter_visible: 1, questions: 0
        });
        window.location = "/admin/jobs/1/10";
      } else {
        Swal.fire("Error!", `${editJobData.data.message}`, "error");
        props.data.editJobData = undefined;
      }
    }
  }, [props.data.editJobData]);

    function onChangeSkill(e) {      
      setselectedskill([]);
      setselectedskillstr("");
      e.map((option) => (
        selectedskill.push(option.key)
      ));
      setselectedskillstr(selectedskill.toString());
      setprevskill(e);
    }

    function onChangeTag(e) {
      setselectedtag([]);
      setselectedtagstr("");
      e.map((option) => (
        selectedtag.push(option.key)
      ));
      setselectedtagstr(selectedtag.toString());
      setprevtag(e);
    }

    function onChangePref(e) {
      setselectedpref([]);
      setselectedprefstr("");
      e.map((option) => (
        console.log(e),
        selectedpref.push(option.key)
      ));
      setselectedprefstr(selectedpref.toString());
      setprevpref(e);
    }
  
    return (
        <>
            <div class="container-scroller">
                <Header name="Jobs" />
                <div class="container-fluid page-body-wrapper">
                    <Sidebar name="Jobs" />
                    <div class="main-panel">
                        <div class="content-wrapper">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="home-tab">
                                        <div class="row flex-grow">
                                            <div class="col-12 grid-margin stretch-card">
                                                <div class="card card-rounded">
                                                    <div class="card-body">
                                                    <form class="forms-sample">
                                                        <div class="row">
                                                            <div class="col-lg-12 col-md-12">
                                                                <div class="form-group">
                                                                    <label>Employer</label>
                                                                    <select
                                                                    class="form-select"
                                                                    name="company_id"
                                                                    value={editdata.company_id}
                                                                    onBlur={validateeditCompany}
                                                                    onChange={onchangeeditdata}>
                                                                    <option value="0">Select Company</option>
                                                                    {companies.map((option) => (
                                                                        <option value={option.id}>{option.name}</option>
                                                                    ))}
                                                                    </select>
                                                                    {errorCompany && ( <div style={mystyle}>{errorCompany}</div> )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12">
                                                                <div class="form-group">
                                                                    <label>Competitor Companies</label>
                                                                    <input
                                                                    class="form-control"
                                                                    type="text"
                                                                    name="comp_comp"
                                                                    value={editdata.comp_comp}
                                                                    onChange={onchangeeditdata}
                                                                    placeholder=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Job title</label>
                                                                <input
                                                                class="form-control"
                                                                type="text"
                                                                name="title"
                                                                value={editdata.title}
                                                                // onBlur={validateeditTitle}
                                                                onChange={onchangeeditdata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Job Designation</label>
                                                                <input
                                                                class="form-control"
                                                                type="text"
                                                                name="designation"
                                                                value={editdata.designation}
                                                                onChange={onchangeeditdata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-4 col-md-4">
                                                                <div class="form-group">
                                                                    <label>Country</label>
                                                                    <select
                                                                    class="form-select"
                                                                    name="country"
                                                                    value={country}
                                                                    // onBlur={validatecountry}
                                                                    onChange={onChangeCountry}
                                                                    >
                                                                    <option value="0">Select Country</option>
                                                                    {countries.map((option) => (
                                                                        <option value={option.id}>
                                                                        {option.name}
                                                                        </option>
                                                                    ))}
                                                                    </select>
                                                                </div>
                                                                </div>
                                                                <div class="col-lg-4 col-md-4">
                                                                <div class="form-group">
                                                                    <label>State</label>
                                                                    <select
                                                                    class="form-select"
                                                                    name="state"
                                                                    value={state}
                                                                    // onBlur={validatestate}
                                                                    onChange={onChangeState}
                                                                    >
                                                                    <option value="0">Select State</option>
                                                                    {states.map((option) => (
                                                                        <option value={option.id}>
                                                                        {option.name}
                                                                        </option>
                                                                    ))}
                                                                    </select>
                                                                </div>
                                                                </div>
                                                                <div class="col-lg-4 col-md-4">
                                                                <div class="form-group">
                                                                    <label>Location*</label><br/>
                                                                    <Select
                                                                    className="basic-multi-select" classNamePrefix="select" name="location"
                                                                    onChange={onChangeLocation} isMulti 
                                                                    // onBlur={validateLocation}
                                                                    value={defaultloc}
                                                                    options={locations.map((option) =>{return {label: option.name, value: option.name, key: option.id}})}
                                                                    >
                                                                    </Select>
                                                                </div>
                                                                </div>                                        
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                              <div class="form-group">
                                                                  <label>Area</label>
                                                                  <input
                                                                  class="form-control" type="text" name="area"
                                                                  value={editdata.area} onChange={onchangeeditdata}
                                                                  placeholder="" />
                                                              </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                              <div class="form-group">
                                                                  <label>Region</label>
                                                                  <input
                                                                  class="form-control" type="text" name="region"
                                                                  value={editdata.region} onChange={onchangeeditdata}
                                                                  placeholder="" />
                                                              </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                              <div class="form-group">
                                                                  <label>Zipcode</label>
                                                                  <input
                                                                  class="form-control" type="text" name="zipcode"
                                                                  value={editdata.zipcode} onChange={onchangeeditdata}
                                                                  placeholder="" />
                                                              </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Job Vacancy*</label>
                                                                <input
                                                                class="form-control"
                                                                type="number"
                                                                name="vacancy"
                                                                value={editdata.vacancy}
                                                                // onBlur={validateeditVacancy}
                                                                onChange={onchangeeditdata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Employment Type*</label>
                                                                <select class="form-select" name="emptype" 
                                                                // onBlur={validateeditEmpType} 
                                                                value={editdata.emptype} onChange={onchangeeditdata}>
                                                                <option value="0">Select Employment Type</option>
                                                                <option value="1">Full Time, Permanent</option>
                                                                <option value="2">Full Time, Contractual</option>
                                                                <option value="3">Full Time, Freelance/Home Based</option>
                                                                <option value="4">Part Time, Permanent</option>
                                                                <option value="5">Part Time, Contractual</option>
                                                                <option value="6">Part Time, Freelance/Home Based</option>
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Job Types*</label>
                                                                <select
                                                                class="form-select"
                                                                name="type"
                                                                value={editdata.type}
                                                                // onBlur={validateeditType}
                                                                onChange={onchangeeditdata}
                                                                >
                                                                <option value="0">Select Job Type</option>
                                                                {types.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Job Category*</label>
                                                                <select
                                                                class="form-select"
                                                                name="category"
                                                                value={editdata.category}
                                                                // onBlur={validateeditCategory}
                                                                onChange={onchangeeditdata}
                                                                >
                                                                <option value="0">Select Job Category</option>
                                                                {categories.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Industries*</label>
                                                                <select
                                                                class="form-select"
                                                                name="industry"
                                                                value={editdata.industry}
                                                                // onBlur={validateeditIndustry}
                                                                onChange={onchangeeditdata}
                                                                >
                                                                <option value="0">Select Industries</option>
                                                                {industries.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Prefered Industries*</label>
                                                                <Select
                                                                className="basic-multi-select" classNamePrefix="select" name="pref_industry"
                                                                onChange={onChangePref} isMulti
                                                                // onBlur={validateeditPrefIndustry}
                                                                options={prefs.map((option) =>{return {label: option.name, value: option.name, key: option.id}})}
                                                                value={prevpref}>
                                                                </Select>
                                                            </div>
                                                            </div>                        
                                                            <div class="col-lg-6 col-md-6">
                                                            <div style={{ color: "black" }}>
                                                                <label for="gender" class="label">
                                                                Gender
                                                                </label>
                                                                <br />
                                                                <br />
                                                                <div class="form-check form-check-inline">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="radio"
                                                                    style={{ margin: "0px" }}
                                                                    id="genderMale"
                                                                    name="gender"
                                                                    value="1"
                                                                    // onBlur={validateeditGender}
                                                                    onChange={onchangeeditdata}
                                                                    checked={editdata.gender === 1}
                                                                />
                                                                <label
                                                                    class="form-check-label"
                                                                    for="inlineRadio1"
                                                                >
                                                                    Male
                                                                </label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="radio"
                                                                    style={{ margin: "0px" }}
                                                                    id="genderFemale"
                                                                    name="gender"
                                                                    value="0"
                                                                    // onBlur={validateeditGender}
                                                                    onChange={onchangeeditdata}
                                                                    checked={editdata.gender === 0}
                                                                />
                                                                <label
                                                                    class="form-check-label"
                                                                    for="inlineRadio2"
                                                                >
                                                                    Female
                                                                </label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="radio"
                                                                    style={{ margin: "0px" }}
                                                                    id="genderAny"
                                                                    name="gender"
                                                                    value="2"
                                                                    // onBlur={validateeditGender}
                                                                    onChange={onchangeeditdata}
                                                                    checked={editdata.gender === 2}
                                                                />
                                                                <label
                                                                    class="form-check-label"
                                                                    for="inlineRadio3"
                                                                >
                                                                    Any
                                                                </label>
                                                                </div>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                            <div class="form-group">
                                                                <label>Expiry Date</label>
                                                                <input
                                                                class="form-control"
                                                                type="date"
                                                                name="expiry_date"
                                                                value={editdata.expiry_date}
                                                                // onBlur={validateeditExpiry}
                                                                onChange={onchangeeditdata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                            <div class="form-group">
                                                                <label>Repost After</label>
                                                                <select
                                                                class="form-select"
                                                                name="repost_after"
                                                                value={editdata.repost_after}
                                                                onChange={onchangeeditdata}
                                                                >
                                                                <option value="0">Select Repost Period</option>
                                                                <option value="7">Weekly</option>
                                                                <option value="15">15 Days</option>
                                                                <option value="30">Monthly</option>
                                                                <option value="90">Quarterly</option>
                                                                <option value="180">6 Months</option>
                                                                <option value="365">Yearly</option>
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                            <div class="form-group">
                                                                <label>Salary From*</label>
                                                                <input
                                                                class="form-control"
                                                                type="number"
                                                                name="salary_from"
                                                                value={editdata.salary_from}
                                                                // onBlur={validateeditFrom}
                                                                onChange={onchangeeditdata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                            <div class="form-group">
                                                                <label>Salary To*</label>
                                                                <input
                                                                class="form-control"
                                                                type="number"
                                                                name="salary_to"
                                                                value={editdata.salary_to}
                                                                // onBlur={validateeditTo}
                                                                onChange={onchangeeditdata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                            <div class="form-group">
                                                                <label>Salary Increment (in %) (If app.)</label>
                                                                <input
                                                                class="form-control"
                                                                type="number"
                                                                name="salary_per"
                                                                value={editdata.salary_per}
                                                                onChange={onchangeeditdata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Currency*</label>
                                                                <select
                                                                class="form-select"
                                                                name="currency"
                                                                value={editdata.currency}
                                                                // onBlur={validateeditCurrency}
                                                                onChange={onchangeeditdata}
                                                                >
                                                                <option value="0">Select Currency</option>
                                                                {currencies.map((option) => (
                                                                    <option value={option.id}>
                                                                    {option.currency_name}
                                                                    </option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Salary Period*</label>
                                                                <select
                                                                class="form-select"
                                                                name="salary_period"
                                                                value={editdata.salary_period}
                                                                // onBlur={validateeditPeriod}
                                                                onChange={onchangeeditdata}
                                                                >
                                                                <option value="0">Select Salary Period</option>
                                                                {SalaryPeriod.map((option) => (
                                                                    <option value={option.id}>
                                                                    {option.period}
                                                                    </option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Show Salary on job post</label>
                                                                <input type="checkbox" name="show_salary" value={editdata.show_salary}></input>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label>Salary Details</label>
                                                                <Editor
                                                                editorClassName= {editorclass}
                                                                editorState={salaryState}
                                                                wrapperClassName={wrapperclass}
                                                                toolbarClassName="toolbar-class"
                                                                onEditorStateChange={onSalaryStateChange}
                                                                hashtag={{
                                                                    separator: ' ',
                                                                    trigger: '#',
                                                                }}
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Career Level*</label>
                                                                <select
                                                                class="form-select"
                                                                name="career_level"
                                                                value={editdata.career_level}
                                                                // onBlur={validateeditCareer}
                                                                onChange={onchangeeditdata}
                                                                >
                                                                <option value="0">Select Career Level</option>
                                                                {career_levels.map((option) => (
                                                                    <option value={option.id}>
                                                                    {option.level}
                                                                    </option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Shift</label>
                                                                <select
                                                                class="form-select"
                                                                name="shift"
                                                                value={editdata.shift}
                                                                // onBlur={validateeditShift}
                                                                onChange={onchangeeditdata}
                                                                >
                                                                <option value="0">Select Shift</option>
                                                                {job_shifts.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Role</label>
                                                                <select
                                                                class="form-select"
                                                                name="position"
                                                                value={editdata.position}
                                                                // onBlur={validateeditPosition}
                                                                onChange={onchangeeditdata}
                                                                >
                                                                <option value="0">Select Role</option>
                                                                {position.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Degree Level</label>
                                                                <select
                                                                class="form-select"
                                                                name="degree_level"
                                                                value={degree}
                                                                // onBlur={validateeditDegree}
                                                                onChange={onChangeDegree}
                                                                >
                                                                <option value="0">Select Degree</option>
                                                                {degree_levels.map((option) => (
                                                                    <option value={option.id}>
                                                                    {option.level}
                                                                    </option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Course</label>
                                                                    <select
                                                                    class="form-select" name="course"
                                                                    value={course} 
                                                                    // onBlur={validateCourse}
                                                                    onChange={onChangeCourse}>
                                                                    <option value="0">Select Course</option>
                                                                    {courses.map((option) => (
                                                                        <option value={option.id}>
                                                                        {option.name}
                                                                        </option>
                                                                    ))}
                                                                    </select>
                                                                </div>
                                                                </div>
                                                                <div class="col-lg-12 col-md-12">
                                                                    <div class="form-group">
                                                                    <label>Specialization or Option</label>
                                                                    <Select
                                                                    className="basic-multi-select" classNamePrefix="select" name="specialization"
                                                                    onChange={onChangeSpecialization} isMulti 
                                                                    // onBlur={validateSpecialization}
                                                                    options={specializations.map((option) =>{return {label: option.name, value: option.name, key: option.id}})}
                                                                    value={prevspecialization}>
                                                                    </Select>
                                                                    </div>
                                                                </div>                                                                                                
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Functional Area</label>
                                                                <select
                                                                class="form-select"
                                                                name="functional_area"
                                                                value={editdata.functional_area}
                                                                // onBlur={validateeditFunctional}
                                                                onChange={onchangeeditdata}
                                                                >
                                                                <option value="0">Select Functional Area</option>
                                                                {functional_areas.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Minimum Experience</label>
                                                                <input
                                                                class="form-control"
                                                                type="number"
                                                                name="min_exp"
                                                                value={editdata.min_exp}
                                                                onChange={onchangeeditdata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Maximum Experience</label>
                                                                <input
                                                                class="form-control"
                                                                type="number"
                                                                name="max_exp"
                                                                value={editdata.max_exp}
                                                                onChange={onchangeeditdata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-12 col-md-12">
                                                                <div class="form-group">
                                                                    <label>Skill*</label>
                                                                    <Select
                                                                    className="basic-multi-select" classNamePrefix="select" name="skill"
                                                                    options={skills.map((option) =>{return {label: option.name, value: option.name, key: option.id}})}
                                                                    value={prevskill}
                                                                    onChange={onChangeSkill} isMulti></Select>
                                                                </div>
                                                                </div>
                                                                <div class="form-group">
                                                                <label>Keywords</label>
                                                                <Select
                                                                    className="basic-multi-select" classNamePrefix="select" name="tag"
                                                                    onChange={onChangeTag} isMulti
                                                                    options={tags.map((option) =>{return {label: option.name, value: option.name, key: option.id}})}
                                                                    value={prevtag}
                                                                    >
                                                                </Select>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label>Description</label>
                                                                <Editor
                                                                editorClassName= {editorclass}
                                                                editorState={editorState}
                                                                wrapperClassName={wrapperclass}
                                                                toolbarClassName="toolbar-class"
                                                                onEditorStateChange={onDescStateChange}
                                                                hashtag={{
                                                                    separator: ' ',
                                                                    trigger: '#',
                                                                }}
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label>Roles and Responsibilities</label>
                                                                <Editor
                                                                editorClassName= {editorclass}
                                                                editorState={roleState}
                                                                wrapperClassName={wrapperclass}
                                                                toolbarClassName="toolbar-class"
                                                                onEditorStateChange={onRoleStateChange}
                                                                hashtag={{
                                                                    separator: ' ',
                                                                    trigger: '#',
                                                                }}
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label>Desired Candidate Profile</label>
                                                                <Editor
                                                                editorClassName= {editorclass}
                                                                editorState={profileState}
                                                                wrapperClassName={wrapperclass}
                                                                toolbarClassName="toolbar-class"
                                                                onEditorStateChange={onProfileStateChange}
                                                                hashtag={{
                                                                    separator: ' ',
                                                                    trigger: '#',
                                                                }}
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label>Perks and Benefits</label>
                                                                <Editor
                                                                editorClassName= {editorclass}
                                                                editorState={perkState}
                                                                wrapperClassName={wrapperclass}
                                                                toolbarClassName="toolbar-class"
                                                                onEditorStateChange={onPerkStateChange}
                                                                hashtag={{
                                                                    separator: ' ',
                                                                    trigger: '#',
                                                                }}
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Reference Code</label>
                                                                <input
                                                                class="form-control"
                                                                type="text"
                                                                name="ref_code"
                                                                value={editdata.ref_code}
                                                                onChange={onchangeeditdata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>                        
                                                        </div>
                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Questionaire</label>
                                                                <select
                                                                class="form-select"
                                                                name="questions"
                                                                value={editdata.questions}
                                                                onChange={onchangeeditdata}
                                                                >
                                                                <option value="0">Select Questionaire</option>
                                                                {questions.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <h3 class="title">Recruiter Information</h3>
                                                        <div class="row">
                                                        <div class="col-lg-6 col-md-6">
                                                            <div style={{ color: "black" }}>
                                                            <label for="" class="label">
                                                            Visible?
                                                            </label>
                                                            <br />
                                                            <br />
                                                            <div class="form-check form-check-inline">
                                                            <input
                                                                class="form-check-input"
                                                                type="radio"
                                                                style={{ margin: "0px" }}
                                                                id="recVisYes"
                                                                name="Recruiter_visible"
                                                                value="1"
                                                                onChange={onchangeeditdata} checked={editdata.Recruiter_visible === 1}
                                                            />
                                                            <label class="form-check-label" for="inlineRadio1"> Yes </label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="radio"
                                                                    style={{ margin: "0px" }}
                                                                    id="recVisNo"
                                                                    name="Recruiter_visible"
                                                                    value="0"
                                                                    onChange={onchangeeditdata}
                                                                    checked={editdata.Recruiter_visible === 0}
                                                                />
                                                                <label class="form-check-label" for="inlineRadio2">No</label>
                                                            </div>
                                                            </div>
                                                        </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Company Name</label>
                                                                <input
                                                                class="form-control"
                                                                type="text"
                                                                name="Recruiter_comp"
                                                                value={editdata.Recruiter_comp}
                                                                onChange={onchangeeditdata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Contact Name</label>
                                                                <input
                                                                class="form-control"
                                                                type="text"
                                                                name="Recruiter_name"
                                                                value={editdata.Recruiter_name}
                                                                onChange={onchangeeditdata}
                                                                // onBlur={validateeditName}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Contact No.</label>
                                                                    <input
                                                                    class="form-control"
                                                                    type="text"
                                                                    name="Recruiter_phoneno"
                                                                    value={editdata.Recruiter_phoneno}
                                                                    onChange={onchangeeditdata}
                                                                    placeholder=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Email</label>
                                                                <input
                                                                class="form-control"
                                                                type="email"
                                                                name="Recruiter_email"
                                                                value={editdata.Recruiter_email}
                                                                onChange={onchangeeditdata}
                                                                // onBlur={validateeditEmail}
                                                                placeholder=""
                                                                />

                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Website</label>
                                                                <input
                                                                class="form-control"
                                                                type="text"
                                                                name="Recruiter_website"
                                                                value={editdata.Recruiter_website}
                                                                onChange={onchangeeditdata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label>Company Details</label>
                                                                <Editor
                                                                editorClassName= {editorclass}
                                                                editorState={companyState}
                                                                wrapperClassName={wrapperclass}
                                                                toolbarClassName="toolbar-class"
                                                                onEditorStateChange={onCompanyStateChange}
                                                                hashtag={{
                                                                    separator: ' ',
                                                                    trigger: '#',
                                                                }}
                                                                />
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="submit" class="btn btn-primary me-2" onClick={saveedittoggle} >
                                                            Submit
                                                        </button>
                                                        <a href={`/admin/jobs/1/10`} title="Cancel Edit Job">
                                                            <button
                                                            class="btn btn-light"
                                                            style={{ border: "0px" }}>
                                                            Cancel
                                                            </button>
                                                        </a>
                                                    </form>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ requestJobDetails, requestEditJob }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);