import Sidebar from './sidebar';
import Header from './header';
import Footer from './footer';
import { RWebShare } from "react-web-share";
import Select from 'react-select';
import './style.css'
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestJobDetails, requestAddJob, requestFormField} from "../Redux/actions";
import Swal from "sweetalert2";
import image from "../images/extraLogo.png";

import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import axios from 'axios';

function Jobs(props) {
    const params = useParams();
    const navigate = useNavigate();
    const [jobData, setjob] = useState([]);

    const [editorState, onDescStateChange] = useState(() => EditorState.createEmpty(),);
    const [roleState, onRoleStateChange] = useState(() => EditorState.createEmpty(),);
    const [profileState, onProfileStateChange] = useState(() => EditorState.createEmpty(),);
    const [perkState, onPerkStateChange] = useState(() => EditorState.createEmpty(),);
    const [salaryState, onSalaryStateChange] = useState(() => EditorState.createEmpty(),);
    const [companyState, onCompanyStateChange] = useState(() => EditorState.createEmpty(),);

    const getHtml     = editorState => draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const getHtmlRole = roleState => draftToHtml(convertToRaw(roleState.getCurrentContent()));
    const getHtmlProfile = profileState => draftToHtml(convertToRaw(profileState.getCurrentContent()));
    const getHtmlPerk = perkState => draftToHtml(convertToRaw(perkState.getCurrentContent()));
    const getHtmlSalary = salaryState => draftToHtml(convertToRaw(salaryState.getCurrentContent()));
    const getHtmlCompany = companyState => draftToHtml(convertToRaw(companyState.getCurrentContent()));

    const [SalaryPeriod, setSalaryPeriod] = useState([]);
    const [career_levels, setcareer_levels] = useState([]);
    const [categories, setcategories] = useState([]);
    const [industries, setindustries] = useState([]);
    const [currencies, setcurrencies] = useState([]);
    const [degree_levels, setdegree_levels] = useState([]);
    const [degree, setdegree] = useState(0);
    const [courses, setcourses] = useState([]);
    const [course, setcourse] = useState(0);
    const [specializations, setspecializations] = useState([]);
    const [specialization, setspecialization] = useState(0);
    const [selectedspecialization, setselectedspecialization] = useState([]);
    const [selectedspecializationstr, setselectedspecializationstr] = useState("");
    const [prevspecialization, setprevspecialization] = useState([]);

    const [functional_areas, setfunctional_areas] = useState([]);
    const [job_shifts, setjob_shifts] = useState([]);
    const [skills, setskills] = useState([]);
    const [tags, settags] = useState([]);
    const [prefs, setprefs] = useState([]);
    const [types, settypes] = useState([]);
    const [position, setposition] = useState([]);
    const [country, setcountry] = useState(0);
    const [state, setstateId] = useState(0);
    const [city, setcity] = useState("");
    const [locations, setlocations] = useState([]);
    const [defaultloc, setdefaultloc] = useState([]);
    const [states, setstates] = useState([]);
    const [countries, setcountries] = useState([]);
  

    const [selectedskill, setselectedskill] = useState([]);
    const [selectedskillstr, setselectedskillstr] = useState("");
    const [prevskill, setprevskill] = useState([]);
    const [selectedtag, setselectedtag] = useState([]);
    const [selectedtagstr, setselectedtagstr] = useState("");    
    const [prevtag, setprevtag] = useState([]);
    const [jobpref, setsjobpref] = useState([]);
    const [selectedpref, setselectedpref] = useState([]);
    const [selectedprefstr, setselectedprefstr] = useState("");
    const [prevpref, setprevpref] = useState([]);
    const [selectedlocation, setselectedlocation] = useState([]);
    const [selectedlocationstr, setselectedlocationstr] = useState("");    
    const [prevlocation, setprevlocation] = useState([]);

    const [token, settoken] = useState("");
    const [companies, setcompanies] = useState([]);
    const [questions, setquestions] = useState([]);

    const [errorCompany, seterrorCompany] = useState("");
    const [errorTitle, seterrorTitle] = useState("");
    const [errorDesignation, seterrorDesignation] = useState("");
    const [errorLocation, seterrorLocation] = useState("");
    const [errorType, seterrorType] = useState("");
    const [errorEmpType, seterrorEmpType] = useState("");
    const [errorCategory, seterrorCategory] = useState("");
    const [errorIndustry, seterrorIndustry] = useState("");
    const [errorPrefIndustry, seterrorPrefIndustry] = useState("");
    const [errorGender, seterrorGender] = useState("");
    const [errorExpiry, seterrorExpiry] = useState("");
    const [errorFrom, seterrorFrom] = useState("");
    const [errorTo, seterrorTo] = useState("");
    const [errorCurrency, seterrorCurrency] = useState("");
    const [errorPeriod, seterrorPeriod] = useState("");
    const [errorCareer, seterrorCareer] = useState("");
    const [errorShift, seterrorShift] = useState("");
    const [errorDegree, seterrorDegree] = useState("");
    const [errorCourse, seterrorCourse] = useState("");
    const [errorSpecialization, seterrorSpecialization] = useState("");
    const [errorFunctional, seterrorFunctional] = useState("");
    const [errorPosition, seterrorPosition] = useState("");
    const [errorDesc, seterrorDesc] = useState("");
    const [errorName, seterrorName] = useState("");
    const [errorEmail, seterrorEmail] = useState("");
    const [errorVacancy, seterrorVacancy] = useState("");
    const [errorcountry, seterrorcountry] = useState("");
    const [errorstate, seterrorstate] = useState("");
  
    const [addmodal, setaddModal] = useState(false);

    const [id, setid] = useState(0);
    const [adddata, setadddata] = useState({
      title: "", location: "", type: 0, emptype: 0, category: 0, industry: 0, selectedprefstr: '',
      skill: 0, gender: 0, expiry_date: '', salary_from: 0, salary_to: 0, currency: 21, salary_period: 11, career_levels: 0, shift: 0, degree_level: 0,
      functional_areas: 0, position: 0, min_exp: 0, max_exp: 0, description: '', desc_perks: '', desc_profile: '', desc_role: '', Recruiter_name: '',
      area: '', region: '', zipcode: '', repost_after: '', country: 101,
      Recruiter_email: '', vacancy: '', ref_code: '', salary_details: '', salary_per: 0,
      Recruiter_comp: '', Recruiter_phoneno: '', Recruiter_details: '', Recruiter_website: '', Recruiter_visible: 1, questions: 0});

    const mystyle = {color: "#D10000", backgroundColor: "#FFD2D2", padding: "3px 10px", border: "1px solid red", borderRadius: "5px", marginTop: "5px"};
    const editorclass = { backgroundColor: "lightgray", padding: "1rem", border: "1px solid #ccc"};  
    const wrapperclass = { padding: "1rem", border: "1px solid #ccc"}
    const toolbarclass = { border: "1px solid #ccc"}

    useEffect(() => {
        props.requestJobDetails({
            id: params.id,
        });
        setjob(props.data.jobDetailsData);
    }, []);

    //form field
    useEffect(() => {
      let formfieldData = props.data.loginData.data.data;
      props.requestFormField({
        token: formfieldData.token,
      });      
      axios.get('https://day.recruiterserp.com/api/job/form-fields')
      .then(res=>{
        setSalaryPeriod(res.data.data.SalaryPeriod);
        setcareer_levels(res.data.data.career_levels);
        setcategories(res.data.data.categories);
        setindustries(res.data.data.industries);
        setprefs(res.data.data.industries);
        setcurrencies(res.data.data.currencies);
        setdegree_levels(res.data.data.degree_levels);
        setfunctional_areas(res.data.data.fun_area);
        setjob_shifts(res.data.data.job_shifts);
        setskills(res.data.data.skills || []);
        settags(res.data.data.tags);
        settypes(res.data.data.types);
        setposition(res.data.data.position);
        settoken(res.data.data.token);
        setcompanies(res.data.data.companies);
        setquestions(res.data.data.questions);  
      })
      .catch(err=>{
          console.log(err);
      })      
    }, [props.data.loginData.data.data]);
      
    function onchangeadddata(e) {
        setadddata((adddata) => ({
          ...adddata,
          [e.target.name]: e.target.value,
        }));
      }
  
    useEffect(() => {
      let countryData = props.data.loginData.data.data.countries;
      if (countryData !== undefined) {
          setcountries(countryData);
      }
      setcountry(101);
      axios.get('https://day.recruiterserp.com/api/state/101')
      .then(res=>{
          setstates(res.data.data.states);
      })
      .catch(err=>{
          console.log(err);
      })
    }, [props.data.loginData.data.data]);
  
    function onChangeCountry(e) {
      console.log(e.target.value);
      setcountry(e.target.value);
      axios.get('https://day.recruiterserp.com/api/state/' + e.target.value)
      .then(res=>{
          setstates(res.data.data.states);
      })
      .catch(err=>{
          console.log(err);
      })
    }    
  
    function onChangeState(e) {
      setstateId(e.target.value);
      axios.get('https://day.recruiterserp.com/api/city/' + e.target.value)
      .then(res=>{
          setlocations(res.data.data.cities);
      })
      .catch(err=>{
          console.log(err);
      })
    }
  
    const onChangeLocation = (event) => {
      setselectedlocation([]);
      setselectedlocationstr("");
      event.map((option) => (
        selectedlocation.push(option.key)
      ));
      setselectedlocationstr(selectedlocation.toString());
      setdefaultloc(event);
    }

    function onChangeDegree(e) {
      console.log(e.target.value);
      setdegree(e.target.value);
      axios.get('https://day.recruiterserp.com/api/courses/' + e.target.value)
      .then(res=>{
          setcourses(res.data.data.courses);
      })
      .catch(err=>{
          console.log(err);
      })
    }    

    function onChangeCourse(e) {
      console.log(e.target.value);
      setcourse(e.target.value);
      axios.get('https://day.recruiterserp.com/api/specializations/' + e.target.value)
      .then(res=>{
          setspecializations(res.data.data.specializations);
      })
      .catch(err=>{
          console.log(err);
      })
    }    

    function onChangeSpecialization(e) {
      setselectedspecialization([]);
      setselectedspecializationstr("");
      e.map((option) => (
        selectedspecialization.push(option.key)
      ));
      setselectedspecializationstr(selectedspecialization.toString());
      console.log('selectedspecialization');
      console.log(selectedspecializationstr);
      setprevspecialization(e);
    }

    useEffect(() => {
      setprevpref(jobpref);
      setselectedpref([]);
      setselectedprefstr("");
      prevpref.map((option) => (
        selectedpref.push(option.id)
      ));
      const dflt= jobpref.map((option) =>{return {label: option.name, value: option.name, key: option.id}});
      console.log(dflt);
      if(dflt !== []){
        setprevpref(dflt);
      }
      setselectedprefstr(selectedpref.toString());
    }, [jobpref]);
    
    function validateCompany() {
      let formIsValid = false;
      if (!adddata["company_id"] === "0") {
        formIsValid = false;
        seterrorCompany("*Select Employer.");
      } else if (typeof !adddata["company_id"] === "undefined") {
        formIsValid = false;
        seterrorCompany("*Select Employer.");
      } else {
        formIsValid = true;
        seterrorCompany("");
      }
      return formIsValid;
    }

    function validateTitle() {
      let formIsValid = false;
      if (!adddata["title"]) {
        formIsValid = false;
        seterrorTitle("*Enter job title.");
      } else if (typeof !adddata["title"] === "undefined") {
        formIsValid = false;
        seterrorTitle("*Enter job title.");
      } else {
        formIsValid = true;
        seterrorTitle("");
      }
      return formIsValid;
    }

    function validateDesignation() {
        let formIsValid = false;
        if (!adddata["designation"]) {
          formIsValid = false;
          seterrorDesignation("*Enter job designation.");
        } else if (typeof !adddata["designation"] === "undefined") {
          formIsValid = false;
          seterrorDesignation("*Enter job designation.");
        } else {
          formIsValid = true;
          seterrorDesignation("");
        }
        return formIsValid;
      }
  
    function validateVacancy() {
      let formIsValid = false;
      if (!adddata["vacancy"] === undefined) {
        formIsValid = false;
        seterrorVacancy("*Enter job vacancy.");
      } else if (adddata["vacancy"] <= 0) {
        formIsValid = false;
        seterrorVacancy("*Vacancy shold be more than 0.");
      } else {
        formIsValid = true;
        seterrorVacancy("");
      }
      return formIsValid;
    }
  
    function validateType() {
      let formIsValid = false;
      if (!adddata["type"] === "0") {
        formIsValid = false;
        seterrorType("*Select job type.");
      } else if (typeof !adddata["type"] === "undefined") {
        formIsValid = false;
        seterrorType("*Select job type.");
      } else {
        formIsValid = true;
        seterrorType("");
      }
      return formIsValid;
    }
  
    function validateIndustry() {
      let formIsValid = false;
      if (!adddata["industry"] === "0") {
        formIsValid = false;
        seterrorIndustry("*Select the industry.");
      } else if (typeof !adddata["industry"] === "undefined") {
        formIsValid = false;
        seterrorIndustry("*Select the industry.");
      } else {
        formIsValid = true;
        seterrorIndustry("");
      }
      return formIsValid;
    }

    function validatePrefIndustry() {
      let formIsValid = false;
      if (!selectedprefstr === "") {
        formIsValid = false;
        seterrorPrefIndustry("*Select the Pref. industry.");
      } else if (typeof !selectedprefstr === "undefined") {
        formIsValid = false;
        seterrorPrefIndustry("*Select the Pref. industry.");
      } else {
        formIsValid = true;
        seterrorPrefIndustry("");
      }
      return formIsValid;
    }

    function validateEmpType() {
      let formIsValid = false;
      if (!adddata["emptype"] === "0") {
        formIsValid = false;
        seterrorEmpType("*Select employment type.");
      } else if (typeof !adddata["emptype"] === "undefined") {
        formIsValid = false;
        seterrorEmpType("*Select employment type.");
      } else {
        formIsValid = true;
        seterrorEmpType("");
      }
      return formIsValid;
    }
    function validateCategory() {
      let formIsValid = false;
      if (!adddata["category"] === "0") {
        formIsValid = false;
        seterrorCategory("*Select job category.");
      } else if (typeof !adddata["category"] === "undefined") {
        formIsValid = false;
        seterrorCategory("*Select job category.");
      } else {
        formIsValid = true;
        seterrorCategory("");
      }
      return formIsValid;
    }
    function validateGender() {
      let formIsValid = false;
      if (typeof !adddata["gender"] === "undefined") {
        formIsValid = false;
        seterrorGender("*Select gender for job.");
      } else {
        formIsValid = true;
        seterrorGender("");
      }
      return formIsValid;
    }
    function validateExpiry() {
      let formIsValid = false;
      var Today = new Date();
      if (typeof !adddata["expiry_date"] !== "undefined") {
        if (new Date(adddata["expiry_date"]).getTime() <= Today.getTime()) {
          formIsValid = false;
          seterrorExpiry("*Please select proper date.");
        } else {
          formIsValid = true;
          seterrorExpiry("");
        }
      } else {
        formIsValid = true;
        seterrorExpiry("");
      }
      return formIsValid;
    }
    function validateFrom() {
      let formIsValid = false;
      if (!adddata["salary_from"] === undefined) {
        formIsValid = false;
        seterrorFrom("*Enter minimum salary.");
      } else if (adddata["salary_from"] <= 0) {
        formIsValid = false;
        seterrorFrom("*Minimum salary shold be more than 0.");
      } else {
        formIsValid = true;
        seterrorFrom("");
      }
      return formIsValid;
    }
    function validateTo() {
      let formIsValid = false;
      if (!adddata["salary_to"] === undefined) {
        formIsValid = false;
        seterrorTo("*Enter maximum salary.");
      } else if (adddata["salary_to"] <= !adddata["salary_from"]) {
        formIsValid = false;
        seterrorTo("*Maximum salary shold be more than Minimum salary.");
      } else {
        formIsValid = true;
        seterrorTo("");
      }
      return formIsValid;
    }
    function validateCurrency() {
      let formIsValid = false;
      if (!adddata["currency"] === "0") {
        formIsValid = false;
        seterrorCurrency("*Select salary currency.");
      } else if (typeof !adddata["currency"] === "undefined") {
        formIsValid = false;
        seterrorCurrency("*Select salary currency.");
      } else {
        formIsValid = true;
        seterrorCurrency("");
      }
      return formIsValid;
    }
    function validatePeriod() {
      let formIsValid = false;
      if (!adddata["salary_period"] === "0") {
        formIsValid = false;
        seterrorPeriod("*Select salary period.");
      } else if (typeof !adddata["salary_period"] === "undefined") {
        formIsValid = false;
        seterrorPeriod("*Select salary period.");
      } else {
        formIsValid = true;
        seterrorPeriod("");
      }
      return formIsValid;
    }
    function validateCareer() {
      let formIsValid = false;
      if (!adddata["career_level"] === "0") {
        formIsValid = false;
        seterrorCareer("*Select career level.");
      } else if (typeof !adddata["career_level"] === "undefined") {
        formIsValid = false;
        seterrorCareer("*Select career level.");
      } else {
        formIsValid = true;
        seterrorCareer("");
      }
      return formIsValid;
    }
    function validateShift() {
      let formIsValid = false;
      if (!adddata["shift"] === "0") {
        formIsValid = false;
        seterrorShift("*Select job shift.");
      } else if (typeof !adddata["shift"] === "undefined") {
        formIsValid = false;
        seterrorShift("*Select job shift.");
      } else {
        formIsValid = true;
        seterrorShift("");
      }
      return formIsValid;
    }

    function validateDegree() {
      let formIsValid = false;
      if (!adddata["degree_level"] === "0") {
        formIsValid = false;
        seterrorDegree("*Select degree level.");
      } else if (typeof !adddata["degree_level"] === "undefined") {
        formIsValid = false;
        seterrorDegree("*Select degree level.");
      } else {
        formIsValid = true;
        seterrorDegree("");
      }
      return formIsValid;
    }
    function validateFunctional() {
      let formIsValid = false;
      if (!adddata["functional_area"] === "0") {
        formIsValid = false;
        seterrorFunctional("*Select functional area.");
      } else if (typeof !adddata["functional_area"] === "undefined") {
        formIsValid = false;
        seterrorFunctional("*Select functional area.");
      } else {
        formIsValid = true;
        seterrorFunctional("");
      }
      return formIsValid;
    }
    function validatePosition() {
      let formIsValid = false;
      if (!adddata["position"] === "0") {
        formIsValid = false;
        seterrorPosition("*Select job position.");
      } else if (typeof !adddata["position"] === "undefined") {
        formIsValid = false;
        seterrorPosition("*Select job position.");
      } else {
        formIsValid = true;
        seterrorPosition("");
      }
      return formIsValid;
    }
    function validateDesc() {
      let formIsValid = false;
      if (!adddata["description"]) {
        formIsValid = false;
        seterrorDesc("*Enter job description.");
      } else if (typeof !adddata["description"] === "undefined") {
        formIsValid = false;
        seterrorDesc("*Enter job description.");
      } else {
        formIsValid = true;
        seterrorDesc("");
      }
      return formIsValid;
    }
    function validateName() {
      let formIsValid = false;
      if (!adddata["Recruiter_name"]) {
        formIsValid = false;
        seterrorName("*Enter recruiter name.");
      } else if (typeof !adddata["Recruiter_name"] !== "undefined") {
        if (!adddata["Recruiter_name"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
          formIsValid = false;
          seterrorName("*Please enter Alphabet characters only.");
        } else {
          formIsValid = true;
          seterrorName("");
        }
      }
      return formIsValid;
    }
    function validateEmail() {
      let formIsValid = false;
      if (!adddata["Recruiter_email"]) {
        formIsValid = false;
        seterrorEmail("*Enter recruiter E-mail ID.");
      } else if (typeof !adddata["Recruiter_email"] !== "undefined") {
        if (
          !adddata["Recruiter_email"].match(
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
          )
        ) {
          formIsValid = false;
          seterrorEmail("*Please enter valid E-mail ID.");
        } else {
          formIsValid = true;
          seterrorEmail("");
        }
      }
      return formIsValid;
    }

    function validateCourse() {
        let formIsValid = false;
        if (course === "0") {
          formIsValid = false;
          seterrorCourse("*Select course.");
        } else if (typeof course === "undefined") {
          formIsValid = false;
          seterrorCourse("*Select course.");
        } else {
          formIsValid = true;
          seterrorCourse("");
        }
        return formIsValid;
      }

      function validateSpecialization() {
        let formIsValid = false;
        if (selectedspecializationstr === "0") {
          formIsValid = false;
          seterrorSpecialization("*Select specialization.");
        } else if (typeof selectedspecializationstr === "undefined") {
          formIsValid = false;
          seterrorSpecialization("*Select specialization.");
        } else {
          formIsValid = true;
          seterrorSpecialization("");
        }
        return formIsValid;
      }
      function validatestate() {
        let formIsValid = false;
        if (state === "0") {
          formIsValid = false;
          seterrorstate("*Select your state.");
        } else if (typeof state === "undefined") {
          formIsValid = false;
          seterrorstate("*Select your state.");
        } else {
          formIsValid = true;
          seterrorstate("");
        }
        return formIsValid;
      }
    
      function validateLocation() {
        let formIsValid = false;
        if (!selectedlocationstr === "") {
          formIsValid = false;
          seterrorLocation("*Select the location.");
        } else if (typeof !selectedlocationstr === "undefined") {
          formIsValid = false;
          seterrorLocation("*Select the location.");
        } else {
          formIsValid = true;
          seterrorLocation("");
        }
        return formIsValid;
      }
      function validatecountry() {
        let formIsValid = false;
        if (country === "0") {
          formIsValid = false;
          seterrorcountry("*Select job country.");
        } else if (typeof country === "undefined") {
          formIsValid = false;
          seterrorcountry("*Select job country.");
        } else {
          formIsValid = true;
          seterrorcountry("");
        }
        return formIsValid;
      }
      function validateCompany() {
        let formIsValid = false;
        if (!adddata["company_id"] === "0") {
          formIsValid = false;
          seterrorCompany("*Select Employer.");
        } else if (typeof !adddata["company_id"] === "undefined") {
          formIsValid = false;
          seterrorCompany("*Select Employer.");
        } else {
          formIsValid = true;
          seterrorCompany("");
        }
        return formIsValid;
      }
  
      function validateForm() {
        let company_id = validateCompany(); let Title = validateTitle();
        let Gender = validateGender(); 
        // let Expiry = validateExpiry(); 
        let From = validateFrom();
        let To = validateTo(); let Period = validatePeriod();
        let Name = validateName(); let Email = validateEmail(); let Vacancy = validateVacancy(); let Course = validateCourse(); let Specialization  = validateSpecialization(); let Location = validateLocation();
        let valid =
          company_id && Title && Gender && From && To  && Period &&
          Name && Email && Vacancy && Course && Specialization && Location;
        return valid;
      }

      //save job
  //save job
  const saveaddtoggle = (e) => {
    e.preventDefault();
    // if (validateForm()) {
      props.requestAddJob({
        data: {
          company_id: adddata.company_id,
          comp_comp: adddata.comp_comp,
          designation: adddata.designation,
          title: adddata.title,
          country: country,
          state: state,
          location: selectedlocationstr,
          area: adddata.area,
          region: adddata.region,
          zipcode: adddata.zipcode,
          type: adddata.type,
          emptype: adddata.emptype,
          category: adddata.category,
          industry: adddata.industry,
          pref_industry: selectedprefstr,
          skill: selectedskillstr,
          gender: adddata.gender,
          expiry_date: adddata.expiry_date,
          salary_from: adddata.salary_from,
          salary_to: adddata.salary_to,
          currency: adddata.currency,
          salary_period: adddata.salary_period,
          career_level: adddata.career_level,
          shift: adddata.shift,
          tag: selectedtagstr,
          degree_level: degree,
          course: course,
          specialization: selectedspecializationstr,
          functional_area: adddata.functional_area,
          position: adddata.position,
          min_exp: adddata.min_exp,
          max_exp: adddata.max_exp,
          description: getHtml(editorState),
          desc_role:  getHtmlRole(roleState),
          desc_profile:  getHtmlProfile(profileState),
          desc_perks:  getHtmlPerk(perkState),
          Recruiter_name: adddata.Recruiter_name,
          Recruiter_email: adddata.Recruiter_email,
          vacancy: adddata.vacancy,
          show_salary: adddata.show_salary,
          ref_code: adddata.ref_code,
          salary_details:  getHtmlSalary(salaryState),
          salary_per: adddata.salary_per,
          Recruiter_comp: adddata.Recruiter_comp, Recruiter_phoneno: adddata.Recruiter_phoneno, 
          Recruiter_details: getHtmlCompany(companyState), Recruiter_website: adddata.Recruiter_website, 
          Recruiter_visible: adddata.Recruiter_visible, questions: adddata.questions,
          repost_after: adddata.repost_after,
        },
      });
    // }
  };


  //save job response
  useEffect(() => {
    let addJobData = props.data.addJobData;
    if (addJobData !== undefined) {
      if (addJobData?.data?.status == "success") {
        Swal.fire(
          "Good job!",
          "Job Added Successfully.",
          "success"
        );
        props.data.addJobData = undefined;
        setadddata({
        title: "", location: "", type: 0, emptype: 0, category: 0, industry: 0, selectedprefstr: '',
        skill: 0, gender: 0, expiry_date: '', salary_from: 0, salary_to: 0, currency: 21, salary_period: 11, career_levels: 0, shift: 0, degree_level: 0,
        functional_areas: 0, position: 0, min_exp: 0, max_exp: 0, description: '', desc_perks: '', desc_profile: '', desc_role: '', Recruiter_name: '',
        area: '', region: '', zipcode: '', repost_after: '', country: 101,
        Recruiter_email: '', vacancy: '', ref_code: '', salary_details: '', salary_per: 0, company_id: 0, comp_comp: '',
        Recruiter_comp: '', Recruiter_phoneno: '', Recruiter_details: '', Recruiter_website: '', Recruiter_visible: 1, questions: 0
        });
        window.location = "/admin/jobs/1/10";

      } else {
        Swal.fire("Error!", `${addJobData.data.message}`, "error");
        props.data.addJobData = undefined;
      }
    }
  }, [props.data.addJobData]);

    function onChangeSkill(e) {      
      setselectedskill([]);
      setselectedskillstr("");
      e.map((option) => (
        selectedskill.push(option.key)
      ));
      setselectedskillstr(selectedskill.toString());
      setprevskill(e);
    }

    function onChangeTag(e) {
      setselectedtag([]);
      setselectedtagstr("");
      e.map((option) => (
        selectedtag.push(option.key)
      ));
      setselectedtagstr(selectedtag.toString());
      setprevtag(e);
    }

    function onChangePref(e) {
      setselectedpref([]);
      setselectedprefstr("");
      e.map((option) => (
        console.log(e),
        selectedpref.push(option.key)
      ));
      setselectedprefstr(selectedpref.toString());
      setprevpref(e);
    }
  
    return (
        <>
            <div class="container-scroller">
                <Header name="Jobs" />
                <div class="container-fluid page-body-wrapper">
                    <Sidebar name="Jobs" />
                    <div class="main-panel">
                        <div class="content-wrapper">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="home-tab">
                                        <div class="row flex-grow">
                                            <div class="col-12 grid-margin stretch-card">
                                                <div class="card card-rounded">
                                                    <div class="card-body">
                                                    <form class="forms-sample">
                                                        <div class="row">
                                                            <div class="col-lg-12 col-md-12">
                                                                <div class="form-group">
                                                                    <label>Employer</label>
                                                                    <select
                                                                    class="form-select"
                                                                    name="company_id"
                                                                    value={adddata.company_id}
                                                                    onBlur={validateCompany}
                                                                    onChange={onchangeadddata}>
                                                                    <option value="0">Select Company</option>
                                                                    {companies.map((option) => (
                                                                        <option value={option.id}>{option.name}</option>
                                                                    ))}
                                                                    </select>
                                                                    {errorCompany && ( <div style={mystyle}>{errorCompany}</div> )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12">
                                                                <div class="form-group">
                                                                    <label>Competitor Companies</label>
                                                                    <input
                                                                    class="form-control"
                                                                    type="text"
                                                                    name="comp_comp"
                                                                    value={adddata.comp_comp}
                                                                    onChange={onchangeadddata}
                                                                    placeholder=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Job title</label>
                                                                <input
                                                                class="form-control"
                                                                type="text"
                                                                name="title"
                                                                value={adddata.title}
                                                                onChange={onchangeadddata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Job Designation</label>
                                                                <input
                                                                class="form-control"
                                                                type="text"
                                                                name="designation"
                                                                value={adddata.designation}
                                                                onChange={onchangeadddata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-4 col-md-4">
                                                                <div class="form-group">
                                                                    <label>Country</label>
                                                                    <select
                                                                    class="form-select"
                                                                    name="country"
                                                                    value={country}
                                                                    onChange={onChangeCountry}
                                                                    >
                                                                    <option value="0">Select Country</option>
                                                                    {countries.map((option) => (
                                                                        <option value={option.id}>
                                                                        {option.name}
                                                                        </option>
                                                                    ))}
                                                                    </select>
                                                                </div>
                                                                </div>
                                                                <div class="col-lg-4 col-md-4">
                                                                <div class="form-group">
                                                                    <label>State</label>
                                                                    <select
                                                                    class="form-select"
                                                                    name="state"
                                                                    value={state}
                                                                    onChange={onChangeState}
                                                                    >
                                                                    <option value="0">Select State</option>
                                                                    {states.map((option) => (
                                                                        <option value={option.id}>
                                                                        {option.name}
                                                                        </option>
                                                                    ))}
                                                                    </select>
                                                                </div>
                                                                </div>
                                                                <div class="col-lg-4 col-md-4">
                                                                <div class="form-group">
                                                                    <label>Location</label><br/>
                                                                    <Select
                                                                    className="basic-multi-select" classNamePrefix="select" name="location"
                                                                    onChange={onChangeLocation} isMulti
                                                                    value={defaultloc}
                                                                    options={locations.map((option) =>{return {label: option.name, value: option.name, key: option.id}})}
                                                                    >
                                                                    </Select>
                                                                </div>
                                                                </div>                                        
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                              <div class="form-group">
                                                                  <label>Area</label>
                                                                  <input
                                                                  class="form-control" type="text" name="area"
                                                                  value={adddata.area} onChange={onchangeadddata}
                                                                  placeholder="" />
                                                              </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                              <div class="form-group">
                                                                  <label>Region</label>
                                                                  <input
                                                                  class="form-control" type="text" name="region"
                                                                  value={adddata.region} onChange={onchangeadddata}
                                                                  placeholder="" />
                                                              </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                              <div class="form-group">
                                                                  <label>Zipcode</label>
                                                                  <input
                                                                  class="form-control" type="text" name="zipcode"
                                                                  value={adddata.zipcode} onChange={onchangeadddata}
                                                                  placeholder="" />
                                                              </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                            <div class="form-group">
                                                                <label>Job Vacancy</label>
                                                                <input
                                                                class="form-control"
                                                                type="number"
                                                                name="vacancy"
                                                                value={adddata.vacancy}
                                                                onChange={onchangeadddata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                            <div class="form-group">
                                                                <label>Employment Type</label>
                                                                <select class="form-select" name="emptype" value={adddata.emptype} onChange={onchangeadddata}>
                                                                <option value="0">Select Employment Type</option>
                                                                <option value="1">Full Time, Permanent</option>
                                                                <option value="2">Full Time, Contractual</option>
                                                                <option value="3">Full Time, Freelance/Home Based</option>
                                                                <option value="4">Part Time, Permanent</option>
                                                                <option value="5">Part Time, Contractual</option>
                                                                <option value="6">Part Time, Freelance/Home Based</option>
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                            <div class="form-group">
                                                                <label>Job Types</label>
                                                                <select
                                                                class="form-select"
                                                                name="type"
                                                                value={adddata.type}
                                                                onChange={onchangeadddata}
                                                                >
                                                                <option value="0">Select Job Type</option>
                                                                {types.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                            <div class="form-group">
                                                                <label>Job Category</label>
                                                                <select
                                                                class="form-select"
                                                                name="category"
                                                                value={adddata.category}
                                                                onChange={onchangeadddata}
                                                                >
                                                                <option value="0">Select Job Category</option>
                                                                {categories.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Industries</label>
                                                                <select
                                                                class="form-select"
                                                                name="industry"
                                                                value={adddata.industry}
                                                                onChange={onchangeadddata}
                                                                >
                                                                <option value="0">Select Industries</option>
                                                                {industries.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Prefered Industries</label>
                                                                <Select
                                                                className="basic-multi-select" classNamePrefix="select" name="pref_industry"
                                                                onChange={onChangePref} isMulti
                                                                options={prefs.map((option) =>{return {label: option.name, value: option.name, key: option.id}})}
                                                                value={prevpref}>
                                                                </Select>
                                                            </div>
                                                            </div>                        
                                                            <div class="col-lg-4 col-md-4">
                                                            <div class="col-lg-12 col-md-12" style={{ color: "black" }}>
                                                                <label for="gender" class="label">
                                                                Gender
                                                                </label>
                                                                <br />
                                                                <div class="form-check form-check-inline">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="radio"
                                                                    style={{ margin: "0px" }}
                                                                    id="genderMale"
                                                                    name="gender"
                                                                    value="1"
                                                                    onChange={onchangeadddata}
                                                                    checked={adddata.gender === 1}
                                                                />
                                                                <label
                                                                    class="form-check-label"
                                                                    for="inlineRadio1"
                                                                >
                                                                    Male
                                                                </label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="radio"
                                                                    style={{ margin: "0px" }}
                                                                    id="genderFemale"
                                                                    name="gender"
                                                                    value="0"
                                                                    onChange={onchangeadddata}
                                                                    checked={adddata.gender === 0}
                                                                />
                                                                <label
                                                                    class="form-check-label"
                                                                    for="inlineRadio2"
                                                                >
                                                                    Female
                                                                </label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="radio"
                                                                    style={{ margin: "0px" }}
                                                                    id="genderAny"
                                                                    name="gender"
                                                                    value="2"
                                                                    onChange={onchangeadddata}
                                                                    checked={adddata.gender === 2}
                                                                />
                                                                <label
                                                                    class="form-check-label"
                                                                    for="inlineRadio3"
                                                                >
                                                                    Any
                                                                </label>
                                                                </div>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                            <div class="form-group">
                                                                <label>Expiry Date</label>
                                                                <input
                                                                class="form-control"
                                                                type="date"
                                                                name="expiry_date"
                                                                value={adddata.expiry_date}
                                                                onChange={onchangeadddata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                            <div class="form-group">
                                                                <label>Repost After</label>
                                                                <select
                                                                class="form-select"
                                                                name="repost_after"
                                                                value={adddata.repost_after}
                                                                onChange={onchangeadddata}
                                                                >
                                                                <option value="0">Select Repost Period</option>
                                                                <option value="7">Weekly</option>
                                                                <option value="15">15 Days</option>
                                                                <option value="30">Monthly</option>
                                                                <option value="90">Quarterly</option>
                                                                <option value="180">6 Months</option>
                                                                <option value="365">Yearly</option>
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                            <div class="form-group">
                                                                <label>Currency</label>
                                                                <select
                                                                class="form-select"
                                                                name="currency"
                                                                value={adddata.currency}
                                                                onChange={onchangeadddata}
                                                                >
                                                                <option value="0">Select Currency</option>
                                                                {currencies.map((option) => (
                                                                    <option value={option.id}>
                                                                    {option.currency_name}
                                                                    </option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                            <div class="form-group">
                                                                <label>Salary From</label>
                                                                <input
                                                                class="form-control"
                                                                type="number"
                                                                name="salary_from"
                                                                value={adddata.salary_from}
                                                                onChange={onchangeadddata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                            <div class="form-group">
                                                                <label>Salary To</label>
                                                                <input
                                                                class="form-control"
                                                                type="number"
                                                                name="salary_to"
                                                                value={adddata.salary_to}
                                                                onChange={onchangeadddata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                            <div class="form-group">
                                                                <label>Salary Period</label>
                                                                <select
                                                                class="form-select"
                                                                name="salary_period"
                                                                value={adddata.salary_period}
                                                                onChange={onchangeadddata}
                                                                >
                                                                <option value="0">Select Salary Period</option>
                                                                {SalaryPeriod.map((option) => (
                                                                    <option value={option.id}>
                                                                    {option.period}
                                                                    </option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Show Salary on job post</label>
                                                                <input type="checkbox" name="show_salary" value={adddata.show_salary}></input>
                                                            </div>
                                                            </div>
                                                            {/* <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label>Salary Details</label>
                                                                <Editor
                                                                editorClassName= {editorclass}
                                                                editorState={salaryState}
                                                                wrapperClassName={wrapperclass}
                                                                toolbarClassName="toolbar-class"
                                                                onEditorStateChange={onSalaryStateChange}
                                                                hashtag={{
                                                                    separator: ' ',
                                                                    trigger: '#',
                                                                }}
                                                                />
                                                            </div>
                                                            </div> */}
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Career Level</label>
                                                                <select
                                                                class="form-select"
                                                                name="career_level"
                                                                value={adddata.career_level}
                                                                onChange={onchangeadddata}
                                                                >
                                                                <option value="0">Select Career Level</option>
                                                                {career_levels.map((option) => (
                                                                    <option value={option.id}>
                                                                    {option.level}
                                                                    </option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Shift</label>
                                                                <select
                                                                class="form-select"
                                                                name="shift"
                                                                value={adddata.shift}
                                                                onChange={onchangeadddata}
                                                                >
                                                                <option value="0">Select Shift</option>
                                                                {job_shifts.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Role</label>
                                                                <select
                                                                class="form-select"
                                                                name="position"
                                                                value={adddata.position}
                                                                onChange={onchangeadddata}
                                                                >
                                                                <option value="0">Select Role</option>
                                                                {position.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Degree Level</label>
                                                                <select
                                                                class="form-select"
                                                                name="degree_level"
                                                                value={degree}
                                                                onChange={onChangeDegree}
                                                                >
                                                                <option value="0">Select Degree</option>
                                                                {degree_levels.map((option) => (
                                                                    <option value={option.id}>
                                                                    {option.level}
                                                                    </option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Course</label>
                                                                    <select
                                                                    class="form-select" name="course"
                                                                    value={course}
                                                                    onChange={onChangeCourse}>
                                                                    <option value="0">Select Course</option>
                                                                    {courses.map((option) => (
                                                                        <option value={option.id}>
                                                                        {option.name}
                                                                        </option>
                                                                    ))}
                                                                    </select>
                                                                </div>
                                                                </div>
                                                                <div class="col-lg-12 col-md-12">
                                                                    <div class="form-group">
                                                                    <label>Specialization or Option</label>
                                                                    <Select
                                                                    className="basic-multi-select" classNamePrefix="select" name="specialization"
                                                                    onChange={onChangeSpecialization} isMulti
                                                                    options={specializations.map((option) =>{return {label: option.name, value: option.name, key: option.id}})}
                                                                    value={prevspecialization}>
                                                                    </Select>
                                                                    </div>
                                                                </div>                                                                                                
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Functional Area</label>
                                                                <select
                                                                class="form-select"
                                                                name="functional_area"
                                                                value={adddata.functional_area}
                                                                onChange={onchangeadddata}
                                                                >
                                                                <option value="0">Select Functional Area</option>
                                                                {functional_areas.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Minimum Experience</label>
                                                                <input
                                                                class="form-control"
                                                                type="number"
                                                                name="min_exp"
                                                                value={adddata.min_exp}
                                                                onChange={onchangeadddata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Maximum Experience</label>
                                                                <input
                                                                class="form-control"
                                                                type="number"
                                                                name="max_exp"
                                                                value={adddata.max_exp}
                                                                onChange={onchangeadddata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-12 col-md-12">
                                                                <div class="form-group">
                                                                    <label>Skill</label>
                                                                    <Select
                                                                    className="basic-multi-select" classNamePrefix="select" name="skill"
                                                                    options={skills.map((option) =>{return {label: option.name, value: option.name, key: option.id}})}
                                                                    value={prevskill}
                                                                    onChange={onChangeSkill} isMulti></Select>
                                                                </div>
                                                                </div>
                                                                <div class="form-group">
                                                                <label>Keywords</label>
                                                                <Select
                                                                    className="basic-multi-select" classNamePrefix="select" name="tag"
                                                                    onChange={onChangeTag} isMulti
                                                                    options={tags.map((option) =>{return {label: option.name, value: option.name, key: option.id}})}
                                                                    value={prevtag}
                                                                    >
                                                                </Select>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label>Description</label>
                                                                <Editor
                                                                editorClassName= {editorclass}
                                                                editorState={editorState}
                                                                wrapperClassName={wrapperclass}
                                                                toolbarClassName="toolbar-class"
                                                                onEditorStateChange={onDescStateChange}
                                                                hashtag={{
                                                                    separator: ' ',
                                                                    trigger: '#',
                                                                }}
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label>Roles and Responsibilities</label>
                                                                <Editor
                                                                editorClassName= {editorclass}
                                                                editorState={roleState}
                                                                wrapperClassName={wrapperclass}
                                                                toolbarClassName="toolbar-class"
                                                                onEditorStateChange={onRoleStateChange}
                                                                hashtag={{
                                                                    separator: ' ',
                                                                    trigger: '#',
                                                                }}
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label>Desired Candidate Profile</label>
                                                                <Editor
                                                                editorClassName= {editorclass}
                                                                editorState={profileState}
                                                                wrapperClassName={wrapperclass}
                                                                toolbarClassName="toolbar-class"
                                                                onEditorStateChange={onProfileStateChange}
                                                                hashtag={{
                                                                    separator: ' ',
                                                                    trigger: '#',
                                                                }}
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label>Perks and Benefits</label>
                                                                <Editor
                                                                editorClassName= {editorclass}
                                                                editorState={perkState}
                                                                wrapperClassName={wrapperclass}
                                                                toolbarClassName="toolbar-class"
                                                                onEditorStateChange={onPerkStateChange}
                                                                hashtag={{
                                                                    separator: ' ',
                                                                    trigger: '#',
                                                                }}
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Reference Code</label>
                                                                <input
                                                                class="form-control"
                                                                type="text"
                                                                name="ref_code"
                                                                value={adddata.ref_code}
                                                                onChange={onchangeadddata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>                        
                                                        </div>
                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Questionaire</label>
                                                                <select
                                                                class="form-select"
                                                                name="questions"
                                                                value={adddata.questions}
                                                                onChange={onchangeadddata}
                                                                >
                                                                <option value="0">Select Questionaire</option>
                                                                {questions.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <h3 class="title">Recruiter Information</h3>
                                                        <div class="row">
                                                        <div class="col-lg-6 col-md-6">
                                                            <div style={{ color: "black" }}>
                                                            <label for="" class="label">
                                                            Visible?
                                                            </label>
                                                            <br />
                                                            <br />
                                                            <div class="form-check form-check-inline">
                                                            <input
                                                                class="form-check-input"
                                                                type="radio"
                                                                style={{ margin: "0px" }}
                                                                id="recVisYes"
                                                                name="Recruiter_visible"
                                                                value="1"
                                                                onChange={onchangeadddata} checked={adddata.Recruiter_visible === 1}
                                                            />
                                                            <label class="form-check-label" for="inlineRadio1"> Yes </label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="radio"
                                                                    style={{ margin: "0px" }}
                                                                    id="recVisNo"
                                                                    name="Recruiter_visible"
                                                                    value="0"
                                                                    onChange={onchangeadddata}
                                                                    checked={adddata.Recruiter_visible === 0}
                                                                />
                                                                <label class="form-check-label" for="inlineRadio2">No</label>
                                                            </div>
                                                            </div>
                                                        </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Company Name</label>
                                                                <input
                                                                class="form-control"
                                                                type="text"
                                                                name="Recruiter_comp"
                                                                value={adddata.Recruiter_comp}
                                                                onChange={onchangeadddata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Recruiter's Name</label>
                                                                <input
                                                                class="form-control"
                                                                type="text"
                                                                name="Recruiter_name"
                                                                value={adddata.Recruiter_name}
                                                                onChange={onchangeadddata}
                                                                onBlur={validateName}
                                                                placeholder=""
                                                                />
                                                                {errorName && (
                                                                <div style={mystyle}>{errorName}</div>
                                                                )}
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Recruiter's No.</label>
                                                                    <input
                                                                    class="form-control"
                                                                    type="text"
                                                                    name="Recruiter_phoneno"
                                                                    value={adddata.Recruiter_phoneno}
                                                                    onChange={onchangeadddata}
                                                                    placeholder=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Email</label>
                                                                <input
                                                                class="form-control"
                                                                type="email"
                                                                name="Recruiter_email"
                                                                value={adddata.Recruiter_email}
                                                                onChange={onchangeadddata}
                                                                onBlur={validateEmail}
                                                                placeholder=""
                                                                />

                                                                {errorEmail && (
                                                                <div style={mystyle}>{errorEmail}</div>
                                                                )}
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Website</label>
                                                                <input
                                                                class="form-control"
                                                                type="text"
                                                                name="Recruiter_website"
                                                                value={adddata.Recruiter_website}
                                                                onChange={onchangeadddata}
                                                                placeholder=""
                                                                />
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                            <div class="form-group">
                                                                <label>Company Details</label>
                                                                <Editor
                                                                editorClassName= {editorclass}
                                                                editorState={companyState}
                                                                wrapperClassName={wrapperclass}
                                                                toolbarClassName="toolbar-class"
                                                                onEditorStateChange={onCompanyStateChange}
                                                                hashtag={{
                                                                    separator: ' ',
                                                                    trigger: '#',
                                                                }}
                                                                />
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="submit" class="btn btn-primary me-2" onClick={saveaddtoggle} >
                                                            Submit
                                                        </button>
                                                        <a href={`/admin/jobs/1/10`} title="Cancel Edit Job">
                                                            <button
                                                            class="btn btn-light"
                                                            style={{ border: "0px" }}>
                                                            {/* <i class="fa fa-pencil-square-o"></i> */}
                                                            Cancel
                                                            </button>
                                                        </a>
                                                    </form>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ requestJobDetails, requestAddJob, requestFormField }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);