import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import { RWebShare } from "react-web-share";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  requestAdminAddSpecialization, requestAdminAllSpecialization, requestAdminSpecialization, requestAdminDeleteSpecialization,
  requestAdminEditSpecialization, requestAdminGetAllCourses, requestAdminAllDegree
} from "../Redux/actions";
import Swal from "sweetalert2";
import axios from 'axios';

function Specialization(props) {
  const [pagination, setpagination] = useState({});
  const navigate = useNavigate();
  const [change, setchange] = useState(false)
  const [pages, setpages] = useState([]);
  const [page_size, setpage_size] = useState(0);

  const [id, setid] = useState(0);
  const [specialization, setspecialization] = useState([]);
  const [courses, setcourses] = useState([]);
  const [degree, setdegree] = useState([]);
  const [degree_id, setdegree_id] = useState(0);
  const [adddata, setadddata] = useState({
    name: "", degree_id: 0,
  });
  const [editdata, seteditdata] = useState({
    name: "", degree_id: 0,
  });

  const params = useParams();
  const [addmodal, setaddModal] = useState(false);
  const [editmodal, seteditModal] = useState(false);

  const [error, seterror] = useState("");
  const [errorcourse, seterrorcourse] = useState("");
  const [errordegree, seterrordegree] = useState("");

  useEffect(() => {
    props.requestAdminAllDegree({
      page: 1,
      page_size: 200,
    });
  }, [1, 200]);

  useEffect(() => {
    let allDegreeData = props.data.allDegreeData;
    if (allDegreeData !== undefined) {
      if (allDegreeData.data) {
        setdegree(allDegreeData.data.data);
      }
    }
  }, [props.data.allDegreeData]);

  function onchangedegree(e) {
    setdegree_id(e.target.value);
    axios.get('https://day.recruiterserp.com/api/courses/' + e.target.value)
      .then(res => {
        setcourses(res.data.data.courses);
      })
      .catch(err => {
        console.log(err);
      })
  }

  function validatename() {
    let formIsValid = false;
    if (!adddata["name"]) {
      formIsValid = false;
      seterror("*Enter Specialization Name.");
    }
    else {
      formIsValid = true;
    }
    return formIsValid;
  }

  function validatecourse() {
    let formIsValid = false;
    if (adddata["course_id"] === "0") {
      formIsValid = false;
      seterrorcourse("*Select course.");
    } else if (typeof adddata["course_id"] === "undefined") {
      formIsValid = false;
      seterrorcourse("*Select course.");
    } else {
      formIsValid = true;
      seterrorcourse("");
    }
    return formIsValid;
  }

  function validatedegree() {
    let formIsValid = false;
    if (adddata["degree_id"] === "0") {
      formIsValid = false;
      seterrordegree("*Select degree.");
    } else if (typeof adddata["degree_id"] === "undefined") {
      formIsValid = false;
      seterrordegree("*Select degree.");
    } else {
      formIsValid = true;
      seterrordegree("");
    }
    return formIsValid;
  }

  function validateAddForm() {
    let validate = validatename(); let course = validatecourse(); let degree_id = validatedegree();
    let valid = validate && course && degree_id;
    return valid;
  }

  function validateedit() {
    let formIsValid = false;
    if (!editdata["name"]) {
      formIsValid = false;
      seterror("*Enter Specialization Name.");
    }
    else {
      formIsValid = true;
    }
    return formIsValid;
  }

  function validateeditcourse() {
    let formIsValid = false;
    if (editdata["course_id"] === "0") {
      formIsValid = false;
      seterrorcourse("*Select course.");
    } else if (typeof editdata["course_id"] === "undefined") {
      formIsValid = false;
      seterrorcourse("*Select course.");
    } else {
      formIsValid = true;
      seterrorcourse("");
    }
    return formIsValid;
  }

  function validateeditdegree() {
    let formIsValid = false;
    if (editdata["degree_id"] === "0") {
      formIsValid = false;
      seterrordegree("*Select degree.");
    } else if (typeof editdata["degree_id"] === "undefined") {
      formIsValid = false;
      seterrordegree("*Select degree.");
    } else {
      formIsValid = true;
      seterrordegree("");
    }
    return formIsValid;
  }

  function validateEditForm() {
    let validate = validateedit(); let course = validateeditcourse(); let degree = validateeditdegree();
    let valid = validate && course && degree;
    return valid;
  }

  function onchangeadddata(e) {
    setadddata((adddata) => ({
      ...adddata,
      [e.target.name]: e.target.value,
    }));
  }

  function onchangeeditdata(e) {
    seteditdata((editdata) => ({
      ...editdata,
      [e.target.name]: e.target.value,
    }));
  }

  const addtoggle = () => {
    setaddModal(!addmodal);
    // props.requestAdminGetAllCourses();
    // setcourses(props.data.loadCourseData.data.data);
  };

  const saveaddtoggle = (e) => {
    e.preventDefault();
    if (validateAddForm()) {
      props.requestAdminAddSpecialization({
        data: {
          name: adddata.name,
          course_id: adddata.course_id,
          degree_id: degree_id,
        },
      });
      setadddata({
        name: "",
        course_id: 0,
        degree_id: 0,
      });
      addtoggle();
    }
  };

  useEffect(() => {
    let addSpecializationData = props.data.addSpecializationData;
    if (addSpecializationData !== undefined) {
      if (addSpecializationData.data) {
        Swal.fire({
          title: 'Good job!',
          text: 'Specialization name added successfully..',
          icon: 'success'
        });
        setchange(!change)
      }
    }
    props.data.addSpecializationData = undefined;
  }, [props.data.addSpecializationData]);

  const edittoggle = (id) => {
    if (editmodal === true) {
      seteditModal(!editmodal);
    } else {
      seteditModal(!editmodal);
      setid(id);
      seteditdata({
        name: " ",
        course_id: 0,
        degree_id: 0,
      });
      props.requestAdminSpecialization({
        id: id,
      });
      // props.requestAdminGetAllCourses();
      // setcourses(props.data.loadCourseData.data.data);
    }
  };

  useEffect(() => {
    let specialization = props.data.specializationData;
    if (specialization !== undefined) {
      if (specialization.data) {
        console.log(specialization.data.data.Specialization[0]);
        seteditdata(specialization.data.data.Specialization[0]);
      }
    }
  }, [props.data.specializationData]);

  const saveedittoggle = (e) => {
    e.preventDefault();

    if (validateEditForm()) {
      props.requestAdminEditSpecialization({
        id: id,
        data: {
          name: editdata.name,
          course_id: editdata.course_id,
          degree_id: degree_id,
        },
      });
      seteditdata({
        name: "",
        course_id: 0,
        degree_id: 0,
      });
      setid(0);
      edittoggle();
    }
  };

  useEffect(() => {
    let editSpecializationData = props.data.editSpecializationData;
    if (editSpecializationData !== undefined) {
      if (editSpecializationData.data) {
        Swal.fire({
          title: 'Good job!',
          text: 'Specialization name updated successfully..',
          icon: 'success'
        });
        setchange(!change)
      }
    }
    props.data.editSpecializationData = undefined;
  }, [props.data.editSpecializationData]);

  useEffect(() => {
    props.requestAdminAllSpecialization({
      page: params.page,
      page_size: params.page_size,
    });
    setpage_size(params.page_size);
  }, [params.page, params.page_size, change]);

  function onChangePageSize(e) {
    setpage_size(e.target.value);
    if (e.target.value > 0) {
      navigate(`/admin/specialization/1/${e.target.value}`);
    }
  }

  useEffect(() => {
    let allSpecializationData = props.data.allSpecializationData;
    console.log(allSpecializationData);
    if (allSpecializationData !== undefined) {
      if (allSpecializationData.data) {
        setspecialization(allSpecializationData.data.data);
        setpagination(allSpecializationData.data.meta);
        if (allSpecializationData.data.meta.last_page < 5) {
          let array = [];
          Array.from(Array(allSpecializationData.data.meta.last_page), (e, i) => {
            array.push(i + 1);
          });
          setpages(array);
        } else {
          let array = [];
          if (allSpecializationData.data.meta.current_page <= 3) {
            array.push(1, 2, 3, 4, 5);
            setpages(array);
          } else if (
            allSpecializationData.data.meta.last_page - allSpecializationData.data.meta.current_page <=
            2
          ) {
            array.push(
              allSpecializationData.data.meta.last_page - 4,
              allSpecializationData.data.meta.last_page - 3,
              allSpecializationData.data.meta.last_page - 2,
              allSpecializationData.data.meta.last_page - 1,
              allSpecializationData.data.meta.last_page
            );
            setpages(array);
          } else {
            array.push(
              Number(allSpecializationData.data.meta.current_page) - 2,
              Number(allSpecializationData.data.meta.current_page) - 1,
              allSpecializationData.data.meta.current_page,
              Number(allSpecializationData.data.meta.current_page) + 1,
              Number(allSpecializationData.data.meta.current_page) + 2
            );
            setpages(array);
          }
        }
      }
    }
  }, [props.data.allSpecializationData]);

  const CustomUI = ({ onSubmit, onCancel }) => (
    <div
      className="custom-ui"
      style={{
        backgroundColor: "white",
        width: "350px",
        padding: "25px",
        border: "1px solid black",
        margin: "auto",
      }}
    >
      <h3>Are you sure?</h3>
      <p>
        Do you really want to delete this records? This process cannot be
        undone.
      </p>
      <div>
        <button
          onClick={onSubmit}
          style={{ border: "0px", backgroundColor: "green", color: "white", padding: "2px 10px" }}
        >
          Yes
        </button>
        <button
          onClick={onCancel}
          style={{
            border: "0px",
            backgroundColor: "red",
            color: "white",
            marginLeft: "10px",
            padding: "2px 10px"
          }}
        >
          No
        </button>
      </div>
    </div>
  );
  function deletedata(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        props.requestAdminDeleteSpecialization({
          id: id,
        });
      }
    })
  }

  useEffect(() => {
    let deleteSpecializationData = props.data.deleteSpecializationData;
    if (deleteSpecializationData !== undefined) {
      if (deleteSpecializationData.data) {
        Swal.fire({
          title: 'Good job!',
          text: 'Specialization name deleted successfully..',
          icon: 'success'
        });
        setchange(!change)
      }
    }
    props.data.deleteSpecializationData = undefined;
  }, [props.data.deleteSpecializationData]);

  function printPage() {
    var printContents =
      "<br/><center><h3>Specializations</h3></center>" +
      document.getElementById("printme").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  return (
    <>
      <div class="container-scroller">
        <Header name="Specialization names" />
        <div class="container-fluid page-body-wrapper">
          <Sidebar name="Options" />

          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-sm-12">
                  <div class="home-tab">
                    <div class="row flex-grow">
                      <div class="col-12 grid-margin stretch-card">
                        <div class="card card-rounded">
                          <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-start">
                              <div class="btn-wrapper">
                                <RWebShare
                                  data={{
                                    text: "Job Portal",
                                    url: `http://localhost:3000/admin/specialization/${params.page}/${params.page_size}`,
                                    title: "Job Portal",
                                  }}
                                >
                                  <p class="btn btn-otline-dark align-items-center">
                                    <i class="icon-share"></i> Share
                                  </p>
                                </RWebShare>
                                <button
                                  onClick={printPage}
                                  class="btn btn-otline-dark"
                                >
                                  <i class="icon-printer"></i> Print
                                </button>
                              </div>
                              <div>
                                <button
                                  class="btn btn-primary btn-md text-white mb-0 me-0"
                                  type="button"
                                  onClick={addtoggle}
                                >
                                  Add new specialization
                                </button>
                                <Modal isOpen={addmodal} toggle={addtoggle}>
                                  <ModalHeader toggle={addtoggle}>
                                    Add Specialization names
                                  </ModalHeader>
                                  <ModalBody>
                                    <form class="forms-sample">
                                      <div class="form-group">
                                        <label>Specialization</label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="name"
                                          name="name"
                                          value={adddata.name}
                                          onBlur={validatename}
                                          onChange={onchangeadddata}
                                        />
                                        {error && <p>{error}</p>}
                                      </div>
                                      <div class="form-group">
                                        <label>Degree</label>
                                        <select
                                          class="form-select"
                                          name="degree_id"
                                          value={degree_id}
                                          onBlur={validatedegree}
                                          onChange={onchangedegree}
                                        >
                                          <option value="0">Select Degree</option>
                                          {degree.map((option) => (
                                            <option value={option.id}>
                                              {option.level}
                                            </option>
                                          ))}
                                        </select>
                                        {errordegree && <p>{errordegree}</p>}
                                      </div>
                                      <div class="form-group">
                                        <label>Courses</label>
                                        <select
                                          class="form-select"
                                          name="course_id"
                                          value={adddata.course_id}
                                          onBlur={validatecourse}
                                          onChange={onchangeadddata}
                                        >
                                          <option value="0">Select Courses</option>
                                          {courses.map((option) => (
                                            <option value={option.id}>
                                              {option.name}
                                            </option>
                                          ))}
                                        </select>
                                        {errorcourse && <p>{errorcourse}</p>}
                                      </div>

                                      <button
                                        type="submit"
                                        class="btn btn-primary me-2"
                                        onClick={saveaddtoggle}
                                      >
                                        Submit
                                      </button>
                                      <button
                                        class="btn btn-light"
                                        onClick={addtoggle}
                                      >
                                        Cancel
                                      </button>
                                    </form>
                                  </ModalBody>
                                </Modal>
                              </div>
                            </div>
                            <br />
                            <div class="table-responsive  mt-1" id="printme">
                              <table class="table select-table">
                                <thead>
                                  <tr>
                                    <th>specialization</th>
                                    <th>course</th>
                                    <th>Created At</th>
                                    <th>Updated At</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {specialization.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <div class="d-flex ">
                                            <div>
                                              <h6>{item.name}</h6>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="d-flex ">
                                            <div>
                                              <h6>{item.coursename}</h6>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <p>{item.created_at !== null ? item.created_at.substring(0, 10) : ''}</p>
                                        </td>
                                        <td>
                                          <p>{item.updated_at !== null ? item.updated_at.substring(0, 10) : ''}</p>
                                        </td>
                                        <td>
                                          <button
                                            class="badge badge-opacity-success"
                                            style={{
                                              border: "0px",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {
                                              edittoggle(item.id);
                                            }}
                                          >
                                            <i class="fa fa-pencil-square-o"></i>
                                          </button>
                                          {editmodal === true && (
                                            <Modal
                                              isOpen={editmodal}
                                              toggle={edittoggle}
                                            >
                                              <ModalHeader toggle={edittoggle}>
                                                Edit Specialization names
                                              </ModalHeader>
                                              <ModalBody>
                                                <form class="forms-sample">
                                                  <div class="form-group">
                                                    <label>Degree</label>
                                                    <select
                                                      class="form-select"
                                                      name="degree_id"
                                                      value={degree_id}
                                                      onBlur={validateeditdegree}
                                                      onChange={onchangedegree}
                                                    >
                                                      <option value="0">Select Degree</option>
                                                      {degree.map((option) => (
                                                        <option value={option.id}>
                                                          {option.level}
                                                        </option>
                                                      ))}
                                                    </select>
                                                    {errordegree && <p>{errordegree}</p>}
                                                  </div>

                                                  <div class="form-group">
                                                    <label>Specialization</label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="name"
                                                      name="name"
                                                      value={editdata.name}
                                                      onBlur={validateedit}
                                                      onChange={
                                                        onchangeeditdata
                                                      }
                                                    />
                                                    {error && <p>{error}</p>}
                                                  </div>
                                                  <div class="form-group">
                                                    <label>Courses</label>
                                                    <select
                                                      class="form-select"
                                                      name="course_id"
                                                      value={editdata.course_id}
                                                      onBlur={validateeditcourse}
                                                      onChange={onchangeeditdata}
                                                    >
                                                      <option value="0">Select Courses</option>
                                                      {courses.map((option) => (
                                                        <option value={option.id}>
                                                          {option.name}
                                                        </option>
                                                      ))}
                                                    </select>
                                                    {errorcourse && <p>{errorcourse}</p>}
                                                  </div>

                                                  <button
                                                    type="submit"
                                                    class="btn btn-primary me-2"
                                                    onClick={saveedittoggle}
                                                  >
                                                    Submit
                                                  </button>
                                                  <button
                                                    class="btn btn-light"
                                                    onClick={edittoggle}
                                                  >
                                                    Cancel
                                                  </button>
                                                </form>
                                              </ModalBody>
                                            </Modal>
                                          )}
                                          <button
                                            class="badge badge-opacity-danger"
                                            style={{
                                              border: "0px",
                                              background: "#fea8a9",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {

                                              deletedata(item.id);

                                            }}
                                          >
                                            <i class="fa fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>

                            <div class="row">
                              <div class="col-10">
                                <div class="pagination center">
                                  <ul class="pagination-list">
                                    {pagination.current_page !== 1 && (
                                      <li>
                                        <a
                                          href={`/admin/specialization/${params.page - 1}/${params.page_size
                                            }`}
                                        >
                                          <i class="fa fa-long-arrow-left"></i>
                                        </a>
                                      </li>
                                    )}
                                    {pages.map((i) => {
                                      return pagination.current_page === i ? (
                                        <li class="active">
                                          <a
                                            href={`/admin/specialization/${i}/${params.page_size}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      ) : (
                                        <li>
                                          <a
                                            href={`/admin/specialization/${i}/${params.page_size}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      );
                                    })}
                                    {pagination.current_page !==
                                      pagination.last_page && (
                                        <li>
                                          <a
                                            href={`/admin/specialization/${Number(params.page) + 1
                                              }/${params.page_size}`}
                                          >
                                            <i class="fa fa-long-arrow-right"></i>
                                          </a>
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="pagination center">
                                  <form onSubmit={onChangePageSize}>
                                    <div class="form-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder={page_size}
                                        name="page_size"
                                        value={page_size}
                                        onChange={onChangePageSize}
                                        style={{ width: "100px" }}
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestAdminAddSpecialization, requestAdminAllSpecialization, requestAdminSpecialization, requestAdminDeleteSpecialization,
      requestAdminEditSpecialization, requestAdminGetAllCourses, requestAdminAllDegree
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Specialization);
